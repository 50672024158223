// TODO: Screen not used anywhere. Need to be cleaned up.
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updatePage } from "reducers/actions/PathwayActions";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const RichTextEditor = () => {
  const { pageId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");

  const handleSubmission = () => {
    dispatch(updatePage({
      _id: pageId,
      type: "WRITTEN",
      writtenContent: value,
    })).then(() => navigate("/pathway-builder/manage-contents"));
  };

  return (
    <>
      <Grid style={{ marginTop: "2em" }} container justifyContent="center">
        <Grid item xs={10} sm={10} lg={10} md={10}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => navigate("/pathway-builder/manage-contents")}>
            Go Back
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "2em" }} container justifyContent="center"
        alignItems="center">
        <Grid item xs={10} sm={10} lg={10} md={10}>
          <ReactQuill
            theme="snow"
            modules={modules}
            value={value}
            onChange={setValue}
            placeholder="Add details here"
          />
        </Grid>
        <Button
          style={{ marginTop: "3em" }}
          variant="outlined"
          endIcon={<SaveIcon />}
          onClick={handleSubmission}>
          Add Written Content To Page
        </Button>
      </Grid>
    </>
  );
};

export default RichTextEditor;
