import { Grid, Skeleton } from "@mui/material";
import PageForm from "components/PathwayBuilderModules/PageForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectPathway } from "reducers/PathwayReducer";

const ManagePage = ({ mode }) => {
  const pageTitle = mode === "create" ? "Creating" : "Editing";
  const { pageId, moduleId } = useParams();
  const { selectedPathway } = useSelector(selectPathway);
  const [moduleDetail, setModuleDetail] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    // Find the index of the module in the selectedPathway using moduleId
    const moduleIndex = selectedPathway?.modules
      .findIndex(moduleInfo => moduleInfo._id === moduleId);

    if (moduleIndex !== -1) {
      const moduleData = selectedPathway.modules[moduleIndex];
      setModuleDetail(moduleData);


      const pageIndex = moduleData.pages.findIndex(page => page._id === pageId);
      if (pageIndex !== -1) {
        setSelectedPage(moduleData.pages[pageIndex]);
      }
    }
  }, []);


  return (<>
    <Grid container justifyContent="center">

      {
        moduleDetail === null ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="rectangular" width={210} height={60} />
          </>
        ) : (
          <>
            <Grid item xs={10} sx={{ mt: 3 }}>
              <PageForm
                selectedModule={moduleDetail}
                pageTitle={pageTitle}
                selectedPage={selectedPage} />
            </Grid>
          </>
        )
      }
    </Grid>
  </>);
};

export default ManagePage;