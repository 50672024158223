import { URLS } from "./url";

export const COACH_USER = "COACH";

export const PAGE_CONTENT_TYPE = {
  WRITTEN: "WRITTEN",
  QUIZ_SURVEY: "QUIZ/SURVEY",
  PERSONA: "PERSONA",
};

export const QUIZ_QUESTION_TYPES = ["RADIO", "SLIDER", "WRITTEN", "LIST"];

export const QUIZ_QUESTION_TYPES_ENUM = {
  RADIO: "RADIO",
  SLIDER: "SLIDER",
  WRITTEN: "WRITTEN",
  LIST: "LIST"
};

export const PERSONA_IMAGE_TYPES = {
  PERSONA_A: "PERSONA_A",
  PERSONA_B: "PERSONA_B",
  PERSONA_C: "PERSONA_C"
};

// Define nav constant values
export const NAV_ITEMS = [
  {
    name: "Dashboard",
    link: URLS.HOME,
    isLoggedInOption: true,
    userType: ["COACH", "ADMIN"],
  },
  {
    name: "Clients",
    link: URLS.CLIENTS,
    isLoggedInOption: true,
    userType: ["COACH", "ADMIN"],
  },
  {
    name: "Pathway Builder",
    link: URLS.PATHWAY_BUILDER,
    isLoggedInOption: true,
    userType: ["COACH", "ADMIN"],
  },
  {
    name: "Chat",
    link: URLS.CHAT,
    isLoggedInOption: true,
    userType: ["COACH", "USER", "PREMIUM_USER", "FAMILY", "ADMIN"],
  }
];

export const DRAWER_WIDTH = 240;

export const PAGE_TYPE_TO_TAB_VALUE = {
  [PAGE_CONTENT_TYPE.WRITTEN]: 0,
  [PAGE_CONTENT_TYPE.QUIZ_SURVEY]: 1,
  [PAGE_CONTENT_TYPE.PERSONA]: 2,
};

export const TAB_VALUE_TO_PAGE_TYPE = {
  0: PAGE_CONTENT_TYPE.WRITTEN,
  1: PAGE_CONTENT_TYPE.QUIZ_SURVEY,
  2: PAGE_CONTENT_TYPE.PERSONA,
};
