import { Input } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotesBox from "../../components/Notes/index.js";
import SideNavbar from "../../components/SideNavbar";
import btn from "../../styles/Button.module.css";
import globals from "../../styles/Globals.module.css";
import fields from "../../styles/InputFields.module.css";

const Notes = () => {
  const navigate = useNavigate();

  return (
    <div className={globals.flex}>
      <SideNavbar />
      <div className={globals.clients_content}>
        <div className={fields.sort_fields}>
          <div className={fields.input_con}>
            <h3>Filter by:</h3>
            <Input
              className={fields.input_fields}
              placeholder="Filter by Active, Inactive..."
            />
          </div>
          <div className={fields.input_con}>
            <h3>Search:</h3>
            <Input
              className={fields.input_fields}
              placeholder="Enter client name or client ID"
            />
          </div>
        </div>
        <button
          className={`${btn.btn} ${btn.btn_primary}`}
          onClick={() => navigate("/clients/notes/add-note")}
        >
          Add Note
        </button>
        <NotesBox />
      </div>
    </div>
  );
};

export default Notes;