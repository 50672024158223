import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar";
import Chat from "./screens/Chat.js";
import PathwayBuilder from "./screens/PathwayBuilder.js";
import Dashboard from "./screens/index.js";

import Details from "./screens/clients/Details.js";
import ManageProgram from "./screens/clients/ManageProgram.js";
import Notes from "./screens/clients/Notes.js";
import Surveys from "./screens/clients/Surveys.js";

import AddNoteScreen from "./screens/notes/AddNoteScreen.js";

import CreateQuizTemplate from "./screens/pathway-builder/CreateQuizTemplate";

import { URLS } from "constants/url";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetError, selectPathway } from "reducers/PathwayReducer";
import { selectUser, setUser } from "reducers/UserReducer";
import Login from "screens/auth/Login";
import ManageModule from "screens/pathway-builder/ManageModule";
import ManagePage from "screens/pathway-builder/ManagePage";
import ManagePathway from "screens/pathway-builder/ManagePathway";
import RichTextEditor from "screens/pathway-builder/RichTextEditor";
import Signup from "./screens/auth/Signup";
import QuizQList from "./screens/quiz/QuizQList";
import QuizQRadio from "./screens/quiz/QuizQRadio";
import QuizQSlider from "./screens/quiz/QuizQSlider";
import QuizQWritten from "./screens/quiz/QuizQWritten";

function App() {

  // Import necessary hooks and functions
  const { user } = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selecting error from pathway reducer.
  const { error } = useSelector(selectPathway);

  // useEffect hook to handle initial component load
  useEffect(() => {

    // Dispatch action to set user from local storage.
    dispatch(setUser());

    // Checking if user is available. If not, navigate to login.
    if (!user) {
      navigate(URLS.LOGIN);
    } else {
      navigate(URLS.HOME);
    }
  }, []);

  // Hook to trigger notification on error.
  useEffect(() => {
    if (error) {
      toast.error(`An error occured: ${error}`, { toastId: "pathway-error" });
      dispatch(resetError());
    }
  }, [error]);

  return (
    <div>
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Dashboard />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/pathway-builder" element={<PathwayBuilder />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/clients" element={<Details />} />
        <Route path="/clients/notes" element={<Notes />} />
        <Route path="/clients/surveys" element={<Surveys />} />
        <Route path="/clients/manage-program" element={<ManageProgram />} />

        <Route path="/clients/notes/add-note" element={<AddNoteScreen />} />
        <Route path={URLS.PATHWAY_BUILDER_CREATE_PATHWAY} element={<ManagePathway />} />
        <Route path={URLS.PATHWAY_BUILDER_EDIT_PATHWAY} element={<ManagePathway />} />
        <Route path={URLS.PATHWAY_BUILDER_CREATE_MODULE} element={<ManageModule mode="create" />} />
        <Route path={URLS.PATHWAY_BUILDER_EDIT_MODULE} element={<ManageModule mode="edit" />} />
        <Route path={URLS.PATHWAY_BUILDER_CREATE_PAGE} element={<ManagePage mode="create" />} />
        <Route path={URLS.PATHWAY_BUILDER_EDIT_PAGE} element={<ManagePage mode="edit" />} />
        <Route path="/pathway-builder/create-quiz-template" element={<CreateQuizTemplate />} />
        <Route path="/pathway-builder/create-quiz/radio-question" element={<QuizQRadio />} />
        <Route path="/pathway-builder/create-quiz/slider-question" element={<QuizQSlider />} />
        <Route path="/pathway-builder/create-quiz/written-question" element={<QuizQWritten />} />
        <Route path="/pathway-builder/create-quiz/list-question" element={<QuizQList />} />
        <Route path="/pathway-builder/create-written/:pageId" element={<RichTextEditor />} />
      </Routes>
    </div>
  );
}

export default App;
