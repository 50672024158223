import UnusedModuleBox from "../../components/ManageProgram/UnusedModule";
import UsedModuleBox from "../../components/ManageProgram/UsedModule";
import SideNavbar from "../../components/SideNavbar";
import globals from "../../styles/Globals.module.css";

const ManageProgram = () => {
  return (
    <div className={globals.flex}>
      <SideNavbar />
      <div className={globals.clients_content}>
        <UsedModuleBox />
        <UnusedModuleBox />
      </div>
    </div>
  );
};

export default ManageProgram;