import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import btn from "../../styles/Button.module.css";
import globals from "../../styles/Globals.module.css";
import styles from "../../styles/screens/PathwayBuilder.module.css";

const CreateQuizTemplate = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      onClick={() => navigate("/pathway-builder/create-pathway")}
    >
      Pathway
    </Link>,
    <Link
      underline="hover"
      key="2"
      onClick={() => navigate("/pathway-builder/create-module")}
    >
      Module
    </Link>,
    <Link
      underline="hover"
      key="3"
      onClick={() => navigate("/pathway-builder/create-page")}
    >
      Page
    </Link>,
    <Link
      underline="hover"
      key="4"
      onClick={() => navigate("/pathway-builder/manage-contents")}
    >
      Contents
    </Link>,
    <Typography
      underline="hover"
      key="5"
    >
      Final Survey
    </Typography>
  ];

  return (
    <div className={`${globals.flex} ${globals.flex_col} ${styles.createPathwayCon}`}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <h1>Create Quiz Template</h1>
      <div className={`${globals.flex} ${globals.flex_col} ${globals.gap_1}`}>
        Content here
      </div>
      <div className={btn.btn_con}>
        <button
          type="submit"
          className={`${btn.btn} ${btn.btn_primary} ${btn.btn_save}`}
          onClick={() => navigate("/pathway-builder/manage-contents")}
        >
          Save
        </button>
        {/* <button 
                    type="button" 
                    className={`${btn.btn} ${btn.btn_secondary} ${btn.btn_save}`} 
                    onClick={() => navigate("/pathway-builder")}
                >
                    Next
                </button> */}
      </div>
    </div>
  );
};

export default CreateQuizTemplate;