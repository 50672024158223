import DeleteIcon from "@mui/icons-material/Delete";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import StraightIcon from "@mui/icons-material/Straight";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses
} from "@mui/material";
import ConfirmationAlert from "components/Alerts/ConfirmationAlert";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetDeletePageSuccess, selectPathway } from "reducers/PathwayReducer";
import { deletePageById, updatePage } from "reducers/actions/PathwayActions";
import styled from "styled-components";

const PageListTable = ({ pages, moduleId }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deletePageSuccess, loading } = useSelector(selectPathway);

  // State variables for managing the delete page confirmation alert.
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [pageToDelete, setPageToDelete] = useState(null);
  pages = [...pages].sort((a, b) => a.orderNumber - b.orderNumber);

  // Styling for table.
  // TODO: Move as a constant.
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f27254",
      color: "white",
    },
  }));

  // Function to handle page deletion request.
  const handlePageDelete = (id) => {
    setPageToDelete(id);
    setIsAlertOpen(true);
  };

  // Function to handle page deletion request.
  const handlePageEdit = (id) => {
    navigate(`/pathway-builder/${moduleId}/${id}/edit-page`);
  };

  // This function handles moving a page up in a list of pages if its order is greater than 1.
  const handlePageMoveUp = (page) => {
    // Making sure that the page is currently not the first page in the module.
    if (page.orderNumber > 1) {
      // Find the index of the previous page (the one above the current page) in the pages array.
      const previousPageIndex = pages.findIndex(
        currentPage => currentPage.orderNumber === page.orderNumber - 1);

      if (previousPageIndex != null && previousPageIndex != -1) {
        // Dispatch an action to update the page's order by decrementing it by 1.
        dispatch(updatePage({ ...page, orderNumber: page.orderNumber - 1, moduleId }));

        // Dispatch an action to update the previous page's order by incrementing it by 1.
        dispatch(updatePage({
          ...pages[previousPageIndex],
          orderNumber: pages[previousPageIndex].orderNumber + 1,
          moduleId
        }));
      }
    }
  };

  // Move page down if the order is less than the length of all pages currently in the module.
  const handlePageMoveDown = (page) => {
    if (page.orderNumber < pages.length) {
      // Find the index of the next page (the one below the current page) in the pages array.
      const nextPageIndex = pages.findIndex(
        currentPage => currentPage.orderNumber === page.orderNumber + 1);

      if (nextPageIndex != null && nextPageIndex != -1) {
        dispatch(updatePage({ ...page, orderNumber: page.orderNumber + 1, moduleId }));

        // Dispatch an action to update the previous page's order by decrementing it by 1.
        dispatch(updatePage({
          ...pages[nextPageIndex],
          orderNumber: pages[nextPageIndex].orderNumber - 1,
          moduleId
        }));
      }
    }
  };

  // Move page to the first in the module.
  const handlePageMoveToTop = (selectedPage) => {
    if (selectedPage.orderNumber > 1) {
      pages.map(page => {
        // Dispatch an action to update the above pages order by incrementing it by 1.
        if (page.orderNumber < selectedPage.orderNumber) {
          dispatch(updatePage({
            ...page,
            orderNumber: page.orderNumber + 1,
            moduleId
          }));
        }
      });

      // Updating the page to the top.
      dispatch(updatePage({ ...selectedPage, orderNumber: 1, moduleId }));
    }
  };

  // Move page to the last in the module.
  const handlePageMoveToBottom = (selectedPage) => {
    if (selectedPage.orderNumber > 0) {

      // Finding the last orderNumber for the pages in the module.
      const orderNumbers = pages.map(obj => obj.orderNumber);
      const maxOrderNumber = Math.max(...orderNumbers);

      pages.map(page => {
        // Dispatch an action to update the above pages order by incrementing it by 1.
        if (page.orderNumber > selectedPage.orderNumber) {
          dispatch(updatePage({
            ...page,
            orderNumber: page.orderNumber - 1,
            moduleId
          }));
        }
      });

      // Updating the page to the top.
      dispatch(updatePage({ ...selectedPage, orderNumber: maxOrderNumber, moduleId }));
    }
  };

  // useEffect hook to react to changes in 'deletePageSuccess'
  useEffect(() => {
    // Display a success toast when 'deletePageSuccess' is true
    if (deletePageSuccess) {
      toast.success("Page deleted successfully", { toastId: "delete-page-success" });

      // Dispatch the 'resetDeletePageSuccess' action to reset the 'deletePageSuccess' state
      dispatch(resetDeletePageSuccess());

      setIsAlertOpen(false); // Close the delete confirmation alert.
    }
  }, [deletePageSuccess]);

  // Function to handle alert delete cancel.
  const handleDeleteCancel = () => {
    setPageToDelete(null);
    setIsAlertOpen(false); // Close the delete confirmation alert.
  };

  // Function to confirm deletion for alert.
  const handleDeleteConfirmation = () => {
    if (pageToDelete) {
      dispatch(deletePageById(pageToDelete));
    }
  };

  return (
    <>
      {/* ConfirmationAlert component for displaying the delete confirmation dialog. */}
      <ConfirmationAlert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onCancel={handleDeleteCancel}
        onAgree={handleDeleteConfirmation}
        title="Delete Page Confirmation"
        content="Are you sure you want to delete the page?" />

      <TableContainer
        component={Paper}
        style={{ borderRadius: "0px", width: "90%", alignSelf: "right" }}>
        <Table sx={{ minWidth: 650 }} aria-label="Draft Pathway Module Pages">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="right">Session Name</StyledTableCell>
              <StyledTableCell align="right">Est. Completion</StyledTableCell>
              <StyledTableCell style={{ width: "20%" }} align="right">Date Created</StyledTableCell>
              <StyledTableCell style={{ width: "30%" }} align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              pages.map((page) => (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={page._id}>
                  <TableCell component="th" scope="row">
                    {page.orderNumber}. {page.title}
                  </TableCell>
                  <TableCell align="right">{page.sessionName}</TableCell>
                  <TableCell align="right">
                    {page.estimatedCompletion} {page.estimatedCompletion > 1 ? "Days" : "Day"}
                  </TableCell>
                  <TableCell align="right">
                    {moment(page.updatedAt).format("MMM Do YY")}
                  </TableCell>
                  <TableCell width="25%" align="right">
                    <IconButton
                      disabled={loading}
                      onClick={() => handlePageMoveUp(page)}
                      size="small"
                      color="default"
                      aria-label="move up">
                      <StraightIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={loading}
                      onClick={() => handlePageMoveToTop(page)}
                      size="small"
                      color="default"
                      aria-label="move to top">
                      <VerticalAlignTopIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={loading}
                      onClick={() => handlePageMoveDown(page)}
                      size="small"
                      color="default"
                      aria-label="move down">
                      <StraightIcon fontSize="small" sx={{ transform: "rotate(180deg)" }} />
                    </IconButton>
                    <IconButton
                      onClick={() => handlePageMoveToBottom(page)}
                      size="small"
                      color="default"
                      aria-label="move to bottom">
                      <VerticalAlignBottomIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handlePageEdit(page._id)}
                      size="small"
                      color="default"
                      aria-label="edit page">
                      <EditTwoToneIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handlePageDelete(page._id)}
                      color="error"
                      aria-label="delete"
                      size="small">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PageListTable;