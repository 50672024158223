import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import SideNavbar from "../../components/SideNavbar";
import globals from "../../styles/Globals.module.css";
import styles from "../../styles/screens/clients/Details.module.css";

const Details = () => {
  return (
    <div className={globals.flex}>
      <SideNavbar />
      <div className={`${globals.clients_content} ${styles.content} ${globals.max_desktop}`}>
        <div className={styles.summary}>
          <h1>First Name, Last Name</h1>
          <p>Last Login: Month Date, Year, Time</p>
        </div>
        <div className={`${globals.flex} ${globals.width_100} ${styles.graphsCon}`}>
          <div>
            <h3>Levels Graph</h3>
            <div className={`${globals.width_100} ${styles.graphImageCon}`}>Graph Image Here</div>
          </div>
          <div>
            <h3>Weekly Login Activity Graph</h3>
            <div className={`${globals.width_100} ${styles.graphImageCon}`}>Graph Image Here</div>
          </div>
        </div>
        <div className={`${globals.flex} ${globals.width_100} ${styles.trackerCon}`}>
          <div className={styles.trackerBox}>
            <p className={styles.trackerValue}>#</p>
            <p className={styles.trackerLabel}>Tracker here</p>
          </div>
          <div className={styles.trackerBox}>
            <p className={styles.trackerValue}>#</p>
            <p className={styles.trackerLabel}>Tracker here</p>
          </div>
          <div className={styles.trackerBox}>
            <p className={styles.trackerValue}>#</p>
            <p className={styles.trackerLabel}>Tracker here</p>
          </div>
          <div className={styles.trackerBox}>
            <p className={styles.trackerValue}>#</p>
            <p className={styles.trackerLabel}>Tracker here</p>
          </div>
        </div>
        <div className={`${globals.flex} ${styles.clientProfileCon}`}>
          <Accordion>
            <AccordionSummary>
              <Typography>Administrative</Typography>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet,consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Typography>Personal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet,consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Details;