export const URLS = {
  HOME: "/",
  SIGNUP: "/signup",
  LOGIN: "/login",
  PATHWAY_BUILDER: "/pathway-builder",
  PATHWAY_BUILDER_CREATE_PATHWAY: "/pathway-builder/create-pathway",
  PATHWAY_BUILDER_EDIT_PATHWAY: "/pathway-builder/:pathwayId/create-pathway",
  PATHWAY_BUILDER_CREATE_MODULE: "/pathway-builder/:pathwayId/create-module",
  PATHWAY_BUILDER_EDIT_MODULE: "/pathway-builder/:pathwayId/edit-module/:moduleId",
  PATHWAY_BUILDER_CREATE_PAGE: "/pathway-builder/:moduleId/create-page",
  PATHWAY_BUILDER_EDIT_PAGE: "/pathway-builder/:moduleId/:pageId/edit-page",
  CHAT: "/chat",
  CLIENTS: "/clients",
  CLIENTS_NOTES: "/clients/notes",
  CLIENTS_SURVEYS: "/clients/surveys",
  CLIENTS_MANAGE_PROGRAMS: "/clients/manage-program",
  CLIENTS_NOTES_ADD: "/clients/notes/add-note",
};