import globals from "../../../styles/Globals.module.css";
import tables from "../../../styles/Tables.module.css";
import styles from "../ManageProgramModule.module.css";
import UnusedModuleBlock from "./UnusedModuleBlock";

const UnusedModuleBox = () => {
  return (
    <div className={`${globals.width_100} ${styles.unusedModuleCon}`}>
      <h2>Unused Modules</h2>
      <table className={globals.width_100}>
        <tr className={`${globals.flex} ${tables.table_headings}`}>
          <th>Order</th>
          <th>Name</th>
          <th>Completed</th>
          <th>Content Type</th>
          <th>Actions</th>
        </tr>
        <UnusedModuleBlock />
      </table>
    </div>
  );
};

export default UnusedModuleBox;