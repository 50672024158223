import { Button, FormControl, Grid, TextField } from "@mui/material";
import { URLS } from "constants/url";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  resetAddModuleFlags,
  resetUpdateModuleFlags,
  selectPathway
} from "reducers/PathwayReducer";
import { createModule, updateModule } from "reducers/actions/PathwayActions";
import btn from "styles/Button.module.css";

const ModuleForm = ({ mode, pageTitle, pathwayId, moduleId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    addModuleSuccess,
    addModuleFail,
    updateModuleSuccess,
    updateModuleFail,
    selectedPathway } = useSelector(selectPathway);

  // State data for the form.
  const [formData, setFormData] = useState({
    title: "",
    sessionName: "",
    estimatedCompletion: 1,
    description: "",
    orderNumber: 1,
  });

  const [formErrors, setFormErrors] = useState({
    title: false,
    sessionName: false,
    estimatedCompletion: false,
    description: false,
  });

  useEffect(() => {
    // Populating input fields if user has selected a module to edit
    if (mode === "edit") {
      const moduleIndex = selectedPathway.modules
        .findIndex((moduleData) => moduleData._id === moduleId);
      const selectedModule = selectedPathway.modules[moduleIndex];

      setFormData({
        title: selectedModule.title,
        sessionName: selectedModule.sessionName,
        estimatedCompletion: selectedModule.estimatedCompletion,
        description: selectedModule.description,
        orderNumber: selectedModule.orderNumber,
      });
    }
  }, []);

  const validateForm = () => {
    const errors = {};

    Object.keys(formData).forEach((fieldName) => {
      if (fieldName == "estimatedCompletion" || fieldName === "orderNumber") {
        if (formData[fieldName] == "") {
          errors[fieldName] = true;
        }
      } else {
        if (formData[fieldName].trim() === "") {
          errors[fieldName] = true;
        }
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleModifyModuleClick = () => {
    if (validateForm()) {
      if (mode === "create") {
        dispatch(
          createModule({
            pathwayId,
            module: {
              ...formData,
              orderNumber: selectedPathway ? selectedPathway.modules.length + 1 : 1,
            },
          })
        );
      } else if (mode === "edit") {
        console.log(formData);
        dispatch(updateModule({
          _id: moduleId,
          ...formData
        }));
      }
    }
  };

  useEffect(() => {
    if (addModuleSuccess) {
      toast.success("Module added successfully", { toastId: "add-module-success" });
      dispatch(resetAddModuleFlags());
      navigate(URLS.PATHWAY_BUILDER_EDIT_PATHWAY);
    }

    if (addModuleFail) {
      toast.error("An error occurred when creating Module", { toastId: "add-module-fail" });
      dispatch(resetAddModuleFlags());
    }

    if (updateModuleSuccess) {
      toast.success("Module updated successfully", { toastId: "add-module-success" });
      dispatch(resetUpdateModuleFlags());
      navigate(URLS.PATHWAY_BUILDER_EDIT_PATHWAY);
    }

    if (updateModuleFail) {
      toast.error("An error occurred when updating Module", { toastId: "add-module-fail" });
      dispatch(resetUpdateModuleFlags());
    }
  }, [addModuleSuccess, addModuleFail, updateModuleSuccess, updateModuleFail]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="title"
            label="Enter module title"
            fullWidth
            size="small"
            value={formData.title}
            error={formErrors.title}
            helperText={formErrors.title ? "Title cannot be blank" : ""}
            onChange={handleInputChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="sessionName"
            label="Enter session name"
            fullWidth
            size="small"
            value={formData.sessionName}
            error={formErrors.sessionName}
            helperText={formErrors.sessionName ? "Session name cannot be blank" : ""}
            onChange={handleInputChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="estimatedCompletion"
            label="Enter estimated completion"
            fullWidth
            size="small"
            error={formErrors.estimatedCompletion}
            helperText={formErrors.estimatedCompletion ? "Est. completion cannot be blank" : ""}
            value={formData.estimatedCompletion}
            onChange={handleInputChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <TextField
              name="description"
              label="Description for module"
              multiline
              rows={4}
              error={formErrors.description}
              helperText={formErrors.description ? "Description cannot be blank" : ""}
              value={formData.description}
              onChange={handleInputChange}
              required
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid item sx={{ mt: 3 }} xs={12}>
        <Button
          onClick={() => navigate(URLS.PATHWAY_BUILDER_EDIT_PATHWAY)}
          sx={{ mr: 2 }}
          variant="contained"
          disableElevation
          className={`${btn.btn_primary} ${btn.btn_simple}`}>
          Cancel
        </Button>
        <Button
          onClick={handleModifyModuleClick}
          variant="contained"
          disableElevation
          className={`${btn.btn_secondary} ${btn.btn_simple}`}>
          {pageTitle} Module
        </Button>
      </Grid>
    </div>
  );
};

export default ModuleForm;
