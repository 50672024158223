import { Alert, Grid, Input, Link, Paper, Stack } from "@mui/material";
import { URLS } from "constants/url";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "reducers/UserReducer";
import { login } from "reducers/actions/UserActions";
import btn from "styles/Button.module.css";
import fields from "styles/InputFields.module.css";

const Login = () => {
  // Dispatch and navigation hooks.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State variables for input fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Error handling state
  const [errorMessage, setErrorMessage] = useState(null);

  // Selecting data from Redux store using the useSelector hook
  const { loading, user, error } = useSelector(selectUser);

  // Function for handling the login action
  const handleLogin = (event) => {
    event.preventDefault();
    dispatch(login({ email, password }));
  };

  // Effect hook that triggers when the "error" variable changes
  useEffect(() => {
    if (error) setErrorMessage(error);
  }, [error]);

  // Effect hook that triggers when the "user" variable changes
  useEffect(() => {
    if (user) {
      navigate(URLS.HOME);
    }
  }, [user]);

  return (
    <>
      <Grid
        style={{ marginTop: "2em" }}
        container
        justifyContent="center"
        alignItems="center">
        <Grid item xl={4} lg={4} md={6} xs={10} sm={8}>
          <Paper style={{ padding: "2em" }} variant="outlined">
            <form onSubmit={handleLogin}>
              <Stack spacing={2}>
                <h2>Login to your account</h2>
                {
                  errorMessage &&
                  (<Alert
                    severity="error"
                    onClose={() => setErrorMessage(null)}>{errorMessage}</Alert>)
                }
                <div>
                  <h3>Email:</h3>
                  <Input
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className={fields.input_fields}
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <h3>Password:</h3>
                  <Input
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className={fields.input_fields}
                    placeholder="Enter your password"
                  />
                </div>
                <Grid justifyContent="center" alignItems="center" container>
                  <button
                    type="submit"
                    className={`${btn.btn} ${btn.btn_primary}`}
                    disabled={loading}
                  >
                    Login
                  </button>
                </Grid>
              </Stack>
            </form>
            <span>{"Don't have an account ? "}<Link
              onClick={() => navigate(URLS.SIGNUP)}>Sign Up</Link></span>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;