import { Grid } from "@mui/material";
import ModuleEditList from "components/PathwayBuilderModules/ModuleEditList";
import PathwayQuickInfoForm from "components/PathwayBuilderModules/PathwayQuickInfoForm";

const ManagePathway = () => {

  return (
    <Grid sx={{ mt: 2 }} container rowSpacing={4}>
      <Grid item xs={12}>
        <PathwayQuickInfoForm />
      </Grid>
      <Grid item xs={12}>
        <ModuleEditList />
      </Grid>
    </Grid>
  );
};

export default ManagePathway;