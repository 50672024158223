import globals from "../../../styles/Globals.module.css";
import tables from "../../../styles/Tables.module.css";
import styles from "../ManageProgramModule.module.css";
import UsedModuleBlock from "./UsedModuleBlock";

const UsedModuleBox = () => {
  return (
    <table className={`${globals.width_100} ${styles.usedModuleCon}`}>
      <tr className={`${globals.flex} ${tables.table_headings}`}>
        <th>Order</th>
        <th>Name</th>
        <th>Completed</th>
        <th>Content Type</th>
        <th>Enabled</th>
        <th>Unlocked</th>
      </tr>
      <UsedModuleBlock />
      <UsedModuleBlock />
    </table>
  );
};

export default UsedModuleBox;