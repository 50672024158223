import globals from "../../styles/Globals.module.css";
import tables from "../../styles/Tables.module.css";

const SurveyResultDetails = () => {
  return (
    <tr className={`${globals.flex} ${tables.table_module}`}>
      <td>Survey #1</td>
      <td>Jun 4th 2023 at 11:01 AM CST</td>
      <td>11 / 64</td>
    </tr>
  );
};

export default SurveyResultDetails;