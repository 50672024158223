import {
  Checkbox,
  FormControlLabel,
  Input
} from "@mui/material";
import ObjectID from "bson-objectid";
import { QUIZ_QUESTION_TYPES_ENUM } from "constants/constants";
import { useState } from "react";
import ReactQuill from "react-quill";
import btn from "styles/Button.module.css";
import globals from "styles/Globals.module.css";
import fields from "styles/InputFields.module.css";
import styles from "styles/screens/PathwayBuilder.module.css";

const ListQuestion = ({ onSave, question }) => {

  const { list } = question || { list: null };

  // Form input states.
  const [title, setTitle] = useState(list ? list.title : "");
  const [isReadOnly, setIsReadOnly] = useState(list ? list.isReadOnly : "");
  const [isRequired, setIsRequired] = useState(list ? list.isRequired : "");
  const [listItemPlaceholder, setListItemPlaceholder] = useState(
    list ? list.listItemPlaceholder : "");
  const [listItemTitle, setListItemTitle] = useState(list ? list.listItemTitle : "");
  const [tip, setTip] = useState(list ? list.tip : "");

  // Function to handle add question click.
  const handleQuestionAdd = () => {
    const listQuestion = {
      title,
      isReadOnly,
      isRequired,
      listItemPlaceholder,
      listItemTitle,
      tip
    };

    onSave({
      list: listQuestion,
      _id: question ? question._id : ObjectID(),
      type: QUIZ_QUESTION_TYPES_ENUM.LIST,
    });

    resetFormFields();
  };

  // Function to reset form fields.
  const resetFormFields = () => {
    setTitle("");
    setIsReadOnly(false);
    setIsRequired(false);
    setListItemPlaceholder("");
    setListItemTitle("");
  };

  return (
    <div className={`${globals.flex} ${globals.flex_wrap} ${globals.flex_col} ${globals.gap_1} ${globals.max_desktop} ${globals.width_100}`}>
      <div>
        <h2>List Question Components</h2>
        <h3>Question Title:</h3>
        <Input
          className={fields.input_fields}
          placeholder="Enter Question Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={`${globals.flex} ${styles.checkBoxCon}`}>
        <FormControlLabel
          control={<Checkbox
            checked={isReadOnly}
            onChange={() => setIsReadOnly(prev => !prev)}
          />}
          label="Read Only"
        />
        <FormControlLabel
          control={<Checkbox
            checked={isRequired}
            onChange={() => setIsRequired(prev => !prev)}
          />}
          label="Required"
        />
      </div>
      <div>
        <h3>List Item Placeholder:</h3>
        <Input
          className={fields.input_fields}
          placeholder="Enter List Item Placeholder"
          variant="outlined"
          value={listItemPlaceholder}
          onChange={(e) => setListItemPlaceholder(e.target.value)}
        />
      </div>
      <div>
        <h3>List Item Title:</h3>
        <Input
          className={fields.input_fields}
          placeholder="Enter List Item Title"
          variant="outlined"
          value={listItemTitle}
          onChange={(e) => setListItemTitle(e.target.value)}
        />
      </div>
      <div>
        <h3>Question Tip:</h3>
        <ReactQuill value={tip} onChange={setTip} />
      </div>
      <button
        type="submit"
        className={`${btn.btn_primary} ${btn.btn_save} ${globals.width_100} ${styles.addQuestion}`}
        onClick={handleQuestionAdd}
      >
        Add Quiz Question
      </button>
    </div>
  );
};

export default ListQuestion;
