import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Button, FormControl, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetSuccessAdd, selectPathway } from "reducers/PathwayReducer";
import { createPathway } from "reducers/actions/PathwayActions";
import btnStyles from "styles/Button.module.css";

const PathwayQuickInfoForm = () => {

  const { selectedPathway, successAdd } = useSelector(selectPathway);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (selectedPathway) {
      setTitle(selectedPathway.title);
      setType(selectedPathway.type);
      setSessionName(selectedPathway.sessionName);
      setDescription(selectedPathway.description);
    }
  }, []);

  const handlePathwaySave = () => {
    dispatch(createPathway({
      _id: selectedPathway ? selectedPathway._id : null,
      title,
      type,
      sessionName,
      description,
      modules: selectedPathway ? selectedPathway.modules : [],
      isDraft: true
    }));
  };

  useEffect(() => {
    if (successAdd) {
      toast.success("Pathway saved", { toastId: "pathway-save" });
      dispatch(resetSuccessAdd());
    }
  }, [successAdd]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center" >
        <Grid container spacing={3} item xs={11} md={11} lg={10}>
          <Grid item xs={6}>
            <TextField
              id="pathway-title"
              label="Enter pathway title"
              value={title}
              fullWidth
              size="small"
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined" required />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="pathway-type"
              label="Enter pathway type"
              value={type}
              fullWidth
              size="small"
              onChange={(e) => setType(e.target.value)}
              variant="outlined" required />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="pathway-session"
              label="Enter session name"
              value={sessionName}
              fullWidth
              size="small"
              onChange={(e) => setSessionName(e.target.value)}
              variant="outlined" required />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="page-description"
                label="Description for pathway"
                multiline
                rows={4}
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)} />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} container justifyContent="center">
        <Grid container item xs={11} md={11} lg={10} justifyContent="flex-end">
          <Button
            className={`${btnStyles.btn_secondary} ${btnStyles.btn_simple}`}
            variant="contained"
            onClick={handlePathwaySave}
            startIcon={<SaveOutlinedIcon />}
            disableElevation>
            Save changes
          </Button>
        </Grid>
      </Grid>
    </>);
};

export default PathwayQuickInfoForm;
