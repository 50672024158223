import { createSlice } from "@reduxjs/toolkit";
import { deleteUser, getUser, login, signup, updateUser } from "./actions/UserActions";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

// functions for "useSelector"
const selectUser = (state) => state.user;

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state) => {
      let user = localStorage.getItem("userData-coachPortal");
      if (user) {
        state.user = JSON.parse(user);
        return;
      }
      state.user = null;
    },
    logout: (state) => {
      localStorage.removeItem("userData-coachPortal");
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    resetUser: (state) => {
      state.loading = false;
      state.error = null;
    },
    setIsOnboardingCompleted: (state, { payload }) => {
      let user = localStorage.getItem("userData-coachPortal");
      if (user) {
        state.user = JSON.parse(user);
        state.user.isOnboardingCompleted = payload;
        localStorage.setItem("userData-coachPortal", JSON.stringify(state.user));
        return;
      }
    },
  },
  extraReducers: {
    // Login Async Reducers
    [login.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload;
    },
    [login.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Signup Async Reducers
    [signup.pending]: (state) => {
      state.loading = true;
    },
    [signup.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload;
    },
    [signup.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get User Profile Async Reducers
    [getUser.pending]: (state) => {
      state.loading = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload;
    },
    [getUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Update User Profile Async Reducers
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload;
    },
    [updateUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Async Reducers
    [deleteUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      localStorage.removeItem("userData-coachPortal");
    },
    [deleteUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export default userSlice.reducer;
export { deleteUser, getUser, login, selectUser, signup, updateUser };
export const {
  logout,
  setUser,
  resetUser,
  fullResetUser,
  setIsOnboardingCompleted
} = userSlice.actions;
