import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Axios";

// send backend request to login to the app
const login = createAsyncThunk("users/login", async ({ email, password }) => {
  try {
    const { data } = await axios.post("/users/login", { email, password });

    // store user data in browser
    localStorage.setItem("userData-coachPortal", JSON.stringify(data));

    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

// send backend request to signup for the app
const signup = createAsyncThunk(
  "users/signup",
  async (userData) => {
    try {
      const { data } = await axios.post("/users", userData);

      // store user data in browser
      localStorage.setItem("userData-coachPortal", JSON.stringify(data));

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

const getUser = createAsyncThunk("users/profile", async (_, thunkAPI) => {
  try {
    const user = thunkAPI.getState().user.user;
    if (user) {
      return user;
    }

    const token = thunkAPI.getState().user.user.token;
    const { data } = await axios.get("/users/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { ...data, token };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
});

const updateUser = createAsyncThunk(
  "/users/update",
  async ({ firstName, lastName, age, email, password, profilePic }, thunkAPI) => {
    try {
      // Create a form and append image with additional fields
      const form = new FormData();
      form.append("profilePic", profilePic);
      form.append("firstName", firstName);
      form.append("lastName", lastName);
      form.append("password", password);
      form.append("email", email);
      form.append("age", age);

      const token = thunkAPI.getState().user.user.token;
      const { data } = await axios.put(
        "/users",
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          },
        }
      );

      // store updated user data in browser
      localStorage.setItem("userData-coachPortal", JSON.stringify({ ...data, token }));

      return { ...data, token };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

const deleteUser = createAsyncThunk(
  "/users/delete",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().user.user.token;
      await axios.delete(
        "/users",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

export { deleteUser, getUser, login, signup, updateUser };

