import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Input } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Editor from "../../components/TextEditor";
import btn from "../../styles/Button.module.css";
import globals from "../../styles/Globals.module.css";
import fields from "../../styles/InputFields.module.css";
import styles from "../../styles/screens/clients/Notes.module.css";

const AddNoteScreen = () => {
  const navigate = useNavigate();

  return (
    <div className={`${globals.clients_content} ${styles.addNotesCon}`}>
      <div
        type="button"
        className={btn.btn_back}
        onClick={() => navigate("/clients/notes")}
      >
        <ArrowBackIosIcon className={btn.btn_back_icon} />
        <p>Back</p>
      </div>
      <h2>Add New Note</h2>
      <div className={`${globals.flex} ${globals.max_desktop} ${globals.width_100} ${styles.newNoteSummary}`}>
        <div className={fields.input_con}>
          <h3>Date & Time:</h3>
          <Input
            className={fields.input_fields}
            placeholder="Select a Date and Time"
          />
        </div>
        <div className={fields.input_con}>
          <h3>Topic:</h3>
          <Input
            className={fields.input_fields}
            placeholder="Add a Topic Tag"
          />
        </div>
        <div className={fields.input_con}>
          <h3>Note Title:</h3>
          <Input
            className={fields.input_fields}
            placeholder="Enter Note Title"
          />
        </div>
      </div>
      <div className={`${globals.max_desktop} ${globals.width_100} ${styles.writtenContent}`}>
        <h3>Written Content:</h3>
        <Editor />
      </div>
      <button
        type="submit"
        className={`${btn.btn} ${btn.btn_primary} ${styles.addNoteBtn}`}
        onClick={() => navigate("/clients/notes")}
      >
        Save Note
      </button>
    </div>
  );
};

export default AddNoteScreen;