import { css, Global } from "@emotion/react";

function DynamicCSSInjector({ userCSS }) {
  const dynamicStyles = css`
  .codeEditor {
    ${userCSS}
  }
`;

  return <Global styles={dynamicStyles} />;
}

export default DynamicCSSInjector;
