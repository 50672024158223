import { configureStore } from "@reduxjs/toolkit";
import pathwayReducer from "./reducers/PathwayReducer";
import userReducer from "./reducers/UserReducer";

const reducer = {
  user: userReducer,
  pathway: pathwayReducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
