import {
  Checkbox,
  FormControlLabel,
  Input
} from "@mui/material";
import ObjectID from "bson-objectid";
import { QUIZ_QUESTION_TYPES_ENUM } from "constants/constants";
import { useState } from "react";
import ReactQuill from "react-quill";
import btn from "styles/Button.module.css";
import globals from "styles/Globals.module.css";
import fields from "styles/InputFields.module.css";
import styles from "styles/screens/PathwayBuilder.module.css";

const WrittenQuestion = ({ onSave, question }) => {

  const { written } = question || { written: null };

  // State for form fields.
  const [title, setTitle] = useState(written ? written.title : "");
  const [isReadOnly, setIsReadOnly] = useState(written ? written.isReadOnly : false);
  const [isRequired, setIsRequired] = useState(written ? written.isRequired : false);
  const [tip, setTip] = useState(written ? written.tip : "");

  const handleAddQuestion = () => {
    const writtenQuestion = {
      title,
      isReadOnly,
      isRequired,
      tip
    };

    onSave({
      written: writtenQuestion,
      _id: question ? question._id : ObjectID(),
      type: QUIZ_QUESTION_TYPES_ENUM.WRITTEN,
    });
    resetFormFields();
  };

  const resetFormFields = () => {
    setTitle("");
    setIsReadOnly(false);
    setIsRequired(false);
    setTip("");
  };

  return (
    <div className={`${globals.flex} ${globals.flex_wrap} ${globals.flex_col} ${globals.gap_1} ${globals.max_desktop} ${globals.width_100}`}>
      <div>
        <h2>Written Question Components</h2>
        <h3>Question Title:</h3>
        <Input
          className={fields.input_fields}
          placeholder="Enter Question Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={`${globals.flex} ${styles.checkBoxCon}`}>
        <FormControlLabel
          control={<Checkbox
            checked={isReadOnly}
            onChange={() => setIsReadOnly(prev => !prev)}
          />}
          label="Read Only"
        />
        <FormControlLabel
          control={<Checkbox checked={isRequired}
            onChange={() => setIsRequired(prev => !prev)} />}
          label="Required"
        />
      </div>
      <div>
        <h3>Question Tip:</h3>
        <ReactQuill value={tip} onChange={setTip} />
      </div>
      <button
        type="submit"
        className={`${btn.btn_primary} ${btn.btn_save} ${globals.width_100} ${styles.addQuestion}`}
        onClick={handleAddQuestion}
      >
        Add Quiz Question
      </button>
    </div>
  );
};

export default WrittenQuestion;
