import { Box, Tab, Tabs } from "@mui/material";

const PageTabContent = ({ tabValue, handleTabChange }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} aria-label="content-tab">
          <Tab label="Written" onClick={() => handleTabChange(0)} />
          <Tab label="Quiz/Survey" onClick={() => handleTabChange(1)} />
          <Tab label="Persona" onClick={() => handleTabChange(2)} />
          <Tab label="Quiz Templates" onClick={() => handleTabChange(3)} />
        </Tabs>
      </Box>
    </Box>
  );
};

export default PageTabContent;
