import { Grid } from "@mui/material";
import ModuleForm from "components/PathwayBuilderModules/ModuleForm";
import { useParams } from "react-router-dom";

const ManageModule = ({ mode }) => {
  const pageTitle = mode === "create" ? "Create" : "Edit";
  const { pathwayId, moduleId } = useParams();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11}>
        <h3 style={{ marginTop: "2em", marginBottom: "1em" }}>{pageTitle} Module</h3>
        <ModuleForm moduleId={moduleId} pageTitle={pageTitle} mode={mode} pathwayId={pathwayId} />
      </Grid>
    </Grid>
  );
};

export default ManageModule;