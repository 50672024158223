import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { PAGE_CONTENT_TYPE } from "constants/constants";
import dompurify from "dompurify";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import DynamicCSSInjector from "./DynamicCSSInjector";

const WrittenTabContent = ({ selectedPage, handleChange }) => {
  const [checked, setChecked] = useState(false);
  const [writtenContent, setWrittenContent] = useState("");
  const [writtenCssContent, setWrittenCssContent] = useState("");

  useEffect(() => {
    handleChange({ content: writtenContent, styling: writtenCssContent });
  }, [writtenContent, writtenCssContent]);

  useEffect(() => {
    if (selectedPage) {
      setWrittenContent((selectedPage.type === PAGE_CONTENT_TYPE.WRITTEN
        && selectedPage.writtenContent != null) ? selectedPage.writtenContent.content : "");
      setWrittenCssContent((selectedPage.type === PAGE_CONTENT_TYPE.WRITTEN
        && selectedPage.writtenContent != null) ? selectedPage.writtenContent.styling : "");
    }
  }, []);

  return (
    <Grid container>
      <DynamicCSSInjector userCSS={writtenCssContent} />
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                disabled
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label="Is Supplemental (Work in Progress)"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid xs={6} item>
            <h3 style={{ marginBottom: "1em" }}>Written Content:</h3>
            <CodeEditor
              value={writtenContent}
              language="html"
              placeholder="You can write HTML code here."
              onChange={(evn) => setWrittenContent(evn.target.value)}
              padding={15}
              style={{
                minHeight: "12em",
                fontSize: 12,
                backgroundColor: "#f5f5f5",
                fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Grid>
          <Grid sx={{ pl: 2 }} xs={6} item>
            <h3 style={{ marginBottom: "1em" }}>CSS Styling</h3>
            <CodeEditor
              value={writtenCssContent}
              language="css"
              placeholder="You can write CSS for the HTML code here."
              onChange={(evn) => setWrittenCssContent(evn.target.value)}
              padding={15}
              style={{
                minHeight: "12em",
                fontSize: 12,
                backgroundColor: "#f5f5f5",
                fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: "1em" }}>HTML preview</h3>
          </Grid>
          <Grid item xs={12}>
            <div className="codeEditor">
              {parse(dompurify.sanitize(writtenContent))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WrittenTabContent;
