import { Input } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import fields from "../styles/InputFields.module.css";
import styles from "../styles/screens/Dashboard.module.css";

const columnsClientsOfInterest = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 180 },
  { field: "lastSeen", headerName: "Last Seen", width: 150 },
  { field: "subscriptionType", headerName: "Subscription Type", width: 180 },
  {
    field: "reason",
    headerName: "Reason",
    width: 200,
    sortable: false,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 180,
    sortable: false,
  },
];

const rowsClientsOfInterest = [
  { id: 1, name: "Jon Snow", lastSeen: "May 2, 2023", subscriptionType: "Pathways", reason: "TBD" },
  { id: 2, name: "Cersei Lannister", lastSeen: "May 9, 2023", subscriptionType: "Coaching", reason: "TBD" },
  { id: 3, name: "Jaime Lannister", lastSeen: "May 11, 2023", subscriptionType: "Pathways", reason: "TBD" },
  { id: 4, name: "Arya Stark", lastSeen: "May 7, 2023", subscriptionType: "Coaching", reason: "TBD" },
  { id: 5, name: "Daenerys Targaryen", lastSeen: "Jun 11, 2023", subscriptionType: "Webinar", reason: "TBD" },
  { id: 6, name: "Melisandre", lastSeen: "May 1, 2023", subscriptionType: "Webinar", reason: "TBD" },
  { id: 7, name: "Ferrara Clifford", lastSeen: "May 15, 2023", subscriptionType: "Coaching", reason: "TBD" },
  { id: 8, name: "Rossini Frances", lastSeen: "May 18, 2023", subscriptionType: "Pathways", reason: "TBD" },
  { id: 9, name: "Harvey Roxie", lastSeen: "May 28, 2023", subscriptionType: "Webinar", reason: "TBD" },
];

const columnsAllClients = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 180 },
  { field: "lastSeen", headerName: "Last Seen", width: 150 },
  { field: "subscriptionType", headerName: "Subscription Type", width: 180 },
  {
    field: "actions",
    headerName: "Actions",
    width: 180,
    sortable: false,
  },
];

const rowsAllClients = [
  { id: 1, name: "Jon Snow", lastSeen: "May 2, 2023", subscriptionType: "Pathways" },
  { id: 2, name: "Cersei Lannister", lastSeen: "May 9, 2023", subscriptionType: "Coaching" },
  { id: 3, name: "Jaime Lannister", lastSeen: "May 11, 2023", subscriptionType: "Pathways" },
  { id: 4, name: "Arya Stark", lastSeen: "May 7, 2023", subscriptionType: "Coaching" },
  { id: 5, name: "Daenerys Targaryen", lastSeen: "Jun 11, 2023", subscriptionType: "Webinar" },
  { id: 6, name: "Melisandre", lastSeen: "May 1, 2023", subscriptionType: "Webinar" },
  { id: 7, name: "Ferrara Clifford", lastSeen: "May 15, 2023", subscriptionType: "Coaching" },
  { id: 8, name: "Rossini Frances", lastSeen: "May 18, 2023", subscriptionType: "Pathways" },
  { id: 9, name: "Harvey Roxie", lastSeen: "May 28, 2023", subscriptionType: "Webinar" },
];

const Dashboard = () => {
  return (
    <div className={styles.dashboardCon}>
      <div className={fields.sort_fields}>
        <div className={fields.input_con}>
          <h3>Filter by:</h3>
          <Input
            className={fields.input_fields}
            placeholder="Filter by Active, Inactive..."
          />
        </div>
        <div className={fields.input_con}>
          <h3>Search:</h3>
          <Input
            className={fields.input_fields}
            placeholder="Enter client name or client ID"
          />
        </div>
      </div>
      <div className={styles.clientsOfInterestTableCon}>
        <h2>Clients of Interest</h2>
        <DataGrid
          rows={rowsClientsOfInterest}
          columns={columnsClientsOfInterest}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
      <div className={styles.allClientsTableCon}>
        <h2>All Clients</h2>
        <DataGrid
          rows={rowsAllClients}
          columns={columnsAllClients}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
      </div>
    </div>
  );
};

export default Dashboard;