import globals from "../../styles/Globals.module.css";
import tables from "../../styles/Tables.module.css";
import SurveyResultDetails from "./SurveyResultDetails";

const SurveyBox = () => {
  return (
    <table className={globals.width_100}>
      <tr className={`${globals.flex} ${tables.table_headings}`}>
        <th>Survey No.</th>
        <th>Completed on</th>
        <th>Total Score</th>
      </tr>
      <SurveyResultDetails />
    </table>
  );
};

export default SurveyBox;