import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./Editor.module.css";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const Editor = () => {
  const [value, setValue] = useState("");

  return (
    <ReactQuill
      className={styles.quillEditor}
      theme="snow"
      modules={modules}
      value={value}
      onChange={setValue}
      placeholder="Add details here"
    />
  );
};

export default Editor;