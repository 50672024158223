import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../Axios";

// send backend request to create pathway.
const createPathway = createAsyncThunk(
  "pathway/create",
  async (pathway, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.post("/pathway", pathway, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.data.message);
    }
  }
);

// send backend request get all pathways
const getAllPathways = createAsyncThunk(
  "pathway/ all",
  async (_, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.get("/pathway",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request get pathways by id
const getPathwayById = createAsyncThunk(
  "pathway get by id",
  async (id, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;
      const { data } = await axios.get(`/pathway/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request delete pathways by id
const deletePathwayById = createAsyncThunk(
  "pathway delete by id",
  async (id, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;
      const { data } = await axios.delete(`/pathway/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

      return data;
    } catch (e) {
      throw new Error(e.response.data.data.message);
    }
  }
);

// send backend request to delete module.
const deleteModule = createAsyncThunk(
  "module delete",
  async (id, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.delete(`/module/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.data.message);
    }
  }
);

// send backend request to delete module.
const getAllModules = createAsyncThunk(
  "module get all",
  async (id, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.get("/module", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request to create page.
const createPage = createAsyncThunk(
  "page/create",
  async (pathway, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.post("/page", pathway, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request to delete module.
const getAllPages = createAsyncThunk(
  "get all pages",
  async (id, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.get("/page", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request to create page.
const deletePageById = createAsyncThunk(
  "page/delete",
  async (pageId, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.delete(`/page/${pageId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request to update page.
const updatePage = createAsyncThunk(
  "page/update",
  async (page, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.put(`/page/${page._id}`, page, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request to get all quiz.
const getAllQuiz = createAsyncThunk(
  "get all quiz",
  async (_, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.get("/page/template-quiz", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }
);

// send backend request to create module.
const createModule = createAsyncThunk(
  "pathway/create module",
  async (moduleData, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.post("/module", moduleData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.data.message);
    }
  }
);

// send backend request to update module.
const updateModule = createAsyncThunk(
  "pathway/update module",
  async (moduleData, thunkAPI) => {
    try {
      const user = thunkAPI.getState().user.user;

      const { data } = await axios.put(`/module/${moduleData._id}`, moduleData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return data;
    } catch (e) {
      throw new Error(e.response.data.data.message);
    }
  }
);

export {
  createModule, createPage,
  createPathway,
  deleteModule,
  deletePageById,
  deletePathwayById,
  getAllModules, getAllPages, getAllPathways, getAllQuiz, getPathwayById, updateModule, updatePage
};

