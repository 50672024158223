import DeleteIcon from "@mui/icons-material/Delete";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import StraightIcon from "@mui/icons-material/Straight";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses
} from "@mui/material";
import ConfirmationAlert from "components/Alerts/ConfirmationAlert";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetDeleteModuleSuccess, selectPathway } from "reducers/PathwayReducer";
import { deleteModule, updateModule } from "reducers/actions/PathwayActions";
import styled from "styled-components";

// This is a functional React component for rendering a list of modules in a table format.
const ModuleListTable = ({ moduleInfo }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // TODO: Move to a constant.
  const StyledTableCell = styled(TableCell)(() => ({
    // Styling for table header cells.
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0b7ea1",
      color: "white",
    },
    // Styling for table body cells.
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // State variables for managing the delete module confirmation alert.
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null);
  const { deleteModuleSuccess, selectedPathway } = useSelector(selectPathway);

  // Function to handle module deletion request.
  const handleModuleDelete = (id) => {
    setModuleToDelete(id);
    setIsAlertOpen(true);
  };

  // useEffect hook to react to changes in 'deleteModuleSuccess'
  useEffect(() => {
    // Display a success toast when 'deleteModuleSuccess' is true
    if (deleteModuleSuccess) {
      toast.success("Module deleted successfully", { toastId: "delete-module-success" });

      // Dispatch the 'resetDeleteModuleSuccess' action to reset the 'deleteModuleSuccess' state
      dispatch(resetDeleteModuleSuccess());

      setIsAlertOpen(false);
    }
  }, [deleteModuleSuccess]);

  // Function to handle alert delete cancel.
  const handleDeleteCancel = () => {
    setModuleToDelete(null);
    setIsAlertOpen(false);
  };

  // Function to confirm deletion for alert.
  const handleDeleteConfirmation = () => {
    if (moduleToDelete) {
      dispatch(deleteModule(moduleToDelete));
    }
  };

  // This function handles moving a module up in a list of modules if its order is greater than 1.
  const handleModuleMoveUp = (clickedModule) => {
    // Making sure that the module is currently not the first in the pathway.
    if (clickedModule.orderNumber > 1) {
      // Find the index of the previous module (the one above the current module) in the modules array.
      const previousModuleIndex = selectedPathway.modules.findIndex(
        currentModule => currentModule.orderNumber === clickedModule.orderNumber - 1);

      if (previousModuleIndex != null && previousModuleIndex != -1) {
        // Dispatch an action to update the module's order by decrementing it by 1.
        dispatch(updateModule({
          ...clickedModule,
          orderNumber: clickedModule.orderNumber - 1
        }));

        // Dispatch an action to update the previous module's order by incrementing it by 1.
        dispatch(updateModule({
          ...selectedPathway.modules[previousModuleIndex],
          orderNumber: selectedPathway.modules[previousModuleIndex].orderNumber + 1
        }));
      }
    }
  };

  // Move module down if the order is less than the length of all modules.
  const handleModuleMoveDown = (clickedModule) => {
    if (clickedModule.orderNumber < selectedPathway.modules.length) {
      // Find the index of the next module (the one below the current module) in the module array.
      const nextModuleIndex = selectedPathway.modules.findIndex(
        currentPage => currentPage.orderNumber === clickedModule.orderNumber + 1);

      if (nextModuleIndex != null && nextModuleIndex != -1) {
        dispatch(updateModule({ ...clickedModule, orderNumber: clickedModule.orderNumber + 1 }));

        // Dispatch an action to update the previous modules's order by decrementing it by 1.
        dispatch(updateModule({
          ...selectedPathway.modules[nextModuleIndex],
          orderNumber: selectedPathway.modules[nextModuleIndex].orderNumber - 1
        }));
      }
    }
  };

  // Move module to the first in the pathway.
  const handleModuleMoveToTop = (selectedModule) => {
    if (selectedModule.orderNumber > 1) {
      selectedPathway.modules.map(page => {
        // Dispatch an action to update the above modules order by incrementing it by 1.
        if (page.orderNumber < selectedModule.orderNumber) {
          dispatch(updateModule({
            ...page,
            orderNumber: page.orderNumber + 1
          }));
        }
      });

      // Updating the module to the top.
      dispatch(updateModule({ ...selectedModule, orderNumber: 1 }));
    }
  };

  // Move module to the last in the pathway.
  const handleModuleMoveToBottom = (selectedModule) => {
    if (selectedModule.orderNumber > 0) {

      // Finding the last orderNumber for the modules in the pathway.
      const orderNumbers = selectedPathway.modules.map(obj => obj.orderNumber);
      const maxOrderNumber = Math.max(...orderNumbers);

      selectedPathway.modules.map(currentModule => {
        // Dispatch an action to update the above modules order by incrementing it by 1.
        if (currentModule.orderNumber > selectedModule.orderNumber) {
          dispatch(updateModule({
            ...currentModule,
            orderNumber: currentModule.orderNumber - 1
          }));
        }
      });

      // Updating the page to the top.
      dispatch(updateModule({ ...selectedModule, orderNumber: maxOrderNumber }));
    }
  };

  return (
    <div>
      {/* ConfirmationAlert component for displaying the delete confirmation dialog. */}
      <ConfirmationAlert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onCancel={handleDeleteCancel}
        onAgree={handleDeleteConfirmation}
        title="Delete Module Confirmation"
        content="Are you sure you want to delete the module?" />

      {/* TableContainer and Table components for displaying module information in a table. */}
      <TableContainer component={Paper} style={{ borderRadius: "0px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="Draft Pathway Modules">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="right">Session Name</StyledTableCell>
              <StyledTableCell align="right">Est. Completion</StyledTableCell>
              <StyledTableCell align="right">Date Created</StyledTableCell>
              <StyledTableCell style={{ width: "15%" }} align="right">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {moduleInfo.orderNumber}. {moduleInfo.title}
              </TableCell>
              <TableCell align="right">{moduleInfo.sessionName}</TableCell>
              <TableCell align="right">{moduleInfo.estimatedCompletion} </TableCell>
              <TableCell align="right">
                {moment(moduleInfo.createdAt).format("MMM Do YY")}
              </TableCell>
              <TableCell width="25%" align="right">
                {/* Buttons for various module actions. */}
                <IconButton
                  onClick={() => handleModuleMoveUp(moduleInfo)}
                  size="small"
                  color="default"
                  aria-label="delete">
                  <StraightIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => handleModuleMoveToTop(moduleInfo)}
                  size="small"
                  color="default"
                  aria-label="delete">
                  <VerticalAlignTopIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => handleModuleMoveDown(moduleInfo)}
                  size="small"
                  color="default"
                  aria-label="delete">
                  <StraightIcon fontSize="small" sx={{ transform: "rotate(180deg)" }} />
                </IconButton>
                <IconButton
                  onClick={() => handleModuleMoveToBottom(moduleInfo)}
                  size="small"
                  color="default"
                  aria-label="delete">
                  <VerticalAlignBottomIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/pathway-builder/${selectedPathway._id}/edit-module/${moduleInfo._id}`)}
                  size="small"
                  color="default"
                  aria-label="edit module">
                  <EditTwoToneIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => handleModuleDelete(moduleInfo._id)}
                  size="small"
                  color="error"
                  aria-label="delete">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ModuleListTable;
