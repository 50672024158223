// All methods used for validation goes here.

// Function to validate an email address using a regular expression.
// It checks if the email has the correct format for typical email addresses.
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// Function to check the strength of a password using a regular expression.
// It ensures that the password contains at least one uppercase letter, one lowercase letter,
// one digit, one special character, and is at least 8 characters long.
export const checkPasswordStrength = (password) => {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};
