import { createSlice } from "@reduxjs/toolkit";
import {
  createModule,
  createPage,
  createPathway,
  deleteModule,
  deletePageById,
  deletePathwayById,
  getAllModules,
  getAllPages,
  getAllPathways,
  getAllQuiz,
  getPathwayById,
  updateModule,
  updatePage,
} from "./actions/PathwayActions";

const initialState = {
  selectedPathway: null,
  loading: false,
  error: null,
  successAdd: false,
  successPathwayGet: false,
  successUpdate: false,
  addModuleSuccess: false,
  addModuleFail: false,
  updateModuleSuccess: false,
  updateModuleFail: false,
  addPageSuccess: null,
  addPageFail: null,
  updatePageSuccess: null,
  updatePageFail: null,
  deleteModuleSuccess: false,
  deletePathwaySuccess: false,
  deletePageSuccess: false,
  pathways: [],
  modules: [],
  allPages: [], // All pages from all modules.
  allTemplateQuiz: [], // All template quiz data.
};

// functions for "useSelector"
const selectPathway = (state) => state.pathway;

const pathwaySlice = createSlice({
  name: "pathway",
  initialState,
  reducers: {
    resetSelectedPathway: (state) => {
      state.selectedPathway = null;
    },
    resetSuccessAdd: (state) => {
      state.successAdd = false;
    },
    resetDeleteModuleSuccess: (state) => {
      state.deleteModuleSuccess = false;
    },
    resetSuccessUpdate: (state) => {
      state.successUpdate = false;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetUpdateModuleFlags: (state) => {
      state.updateModuleSuccess = false;
      state.updateModuleFail = false;
    },
    resetDeletePathwaySuccess: (state) => {
      state.deletePathwaySuccess = false;
    },
    resetSuccessPathwayGet: (state) => {
      state.successPathwayGet = false;
    },
    resetDeletePageSuccess: (state) => {
      state.deletePageSuccess = false;
    },
    resetAddModuleFlags: (state) => {
      state.addModuleSuccess = false;
      state.addModuleFail = false;
    },
    resetAddPageFlags: (state) => {
      state.addPageSuccess = null;
      state.addPageFail = null;
    },
    resetUpdatePageFlags: (state) => {
      state.updatePageSuccess = null;
      state.updatePageFail = null;
    },
    sortModulesInSelectedPathway: (state) => {
      state.selectedPathway.modules.sort((a, b) => a.orderNumber - b.orderNumber);
    },
  },
  extraReducers: {
    // Create Pathway Async Reducers
    [createPathway.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.successAdd = false;
    },
    [createPathway.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.successAdd = true;
      state.selectedPathway = payload;
    },
    [createPathway.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.successAdd = false;
    },

    // Get All Pathway Async Reducers
    [getAllPathways.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getAllPathways.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.pathways = payload;
    },
    [getAllPathways.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get All Modules Async Reducers
    [getAllModules.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getAllModules.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.modules = payload;
    },
    [getAllModules.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Get Pathway Async Reducers
    [getPathwayById.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.selectedPathway = null;
      state.successPathwayGet = false;
    },
    [getPathwayById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.selectedPathway = payload;
      state.successPathwayGet = true;
    },
    [getPathwayById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Pathway Async Reducers
    [deletePathwayById.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.deletePathwaySuccess = false;
    },
    [deletePathwayById.fulfilled]: (state) => {
      state.loading = false;
      state.error = null;
      state.deletePathwaySuccess = true;
    },
    [deletePathwayById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Module Async Reducers
    [deleteModule.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.deleteModuleSuccess = false;
    },
    [deleteModule.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      if (state.selectedPathway && state.selectedPathway.modules) {
        // Filter out the module with the given _id and create a new array without it.
        const updatedModules = state.selectedPathway
          .modules.filter(moduleInfo => moduleInfo._id !== payload.moduleId);
        console.log(updatedModules);
        state.selectedPathway.modules = updatedModules;
      }
      state.deleteModuleSuccess = true;
    },
    [deleteModule.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.deleteModuleSuccess = false;
    },

    // Create Page Async Reducers
    [createPage.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.addPageSuccess = null;
      state.addPageFail = null;
    },
    [createPage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      // Find the index of the module in the selectedPathway using moduleId
      const moduleIndex = state.selectedPathway.modules
        .findIndex(moduleInfo => moduleInfo._id === payload.moduleId);

      if (moduleIndex !== -1) {
        // Update the pages array of the found module with the new page object
        state.selectedPathway.modules[moduleIndex].pages.push(payload);
      }
      state.addPageSuccess = true;
      state.addPageFail = null;
    },
    [createPage.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.addPageFail = true;
      state.addPageSuccess = null;
    },

    // Get All Pages Async Reducers
    [getAllPages.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getAllPages.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.allPages = payload;
    },
    [getAllPages.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Delete Page Async Reducers
    [deletePageById.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.deletePageSuccess = false;
    },
    [deletePageById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;

      const id = payload.data.id; // Extract pageId from payload

      // Find the index of the module containing the page to be deleted using pageId
      const moduleIndex = state.selectedPathway.modules.findIndex(moduleInfo =>
        moduleInfo.pages.some(page => page._id === id)
      );

      if (moduleIndex !== -1) {
        // Filter out the page with the specified pageId from the pages array of the module
        state.selectedPathway.modules[moduleIndex].pages =
          state.selectedPathway.modules[moduleIndex].pages.filter(page => page._id !== id);
      }
      state.deletePageSuccess = true;
    },
    [deletePageById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.deletePageSuccess = false;
    },

    // Update Page Async Reducers
    [updatePage.pending]: (state) => {
      state.error = null;
      state.updatePageSuccess = false;
      state.updatePageFail = false;
      state.loading = true;
    },
    [updatePage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      const { updatedPage } = payload.data;

      // Find the index of the module containing the page to be deleted using pageId
      const moduleIndex = state.selectedPathway.modules.findIndex(moduleInfo =>
        moduleInfo.pages.some(page => page._id === updatedPage._id)
      );

      if (moduleIndex !== -1) {
        // Find the index of the page to be deleted in the pages array of the module
        const pageIndex = state.selectedPathway.modules[moduleIndex].pages
          .findIndex(page => page._id === updatedPage._id);

        if (pageIndex !== -1) {
          // Removing the current page in selectedPathway
          state.selectedPathway.modules[moduleIndex].pages.splice(pageIndex, 1);

          // Adding the updated page to the new module.
          const newModuleIndex = state.selectedPathway.modules
            .findIndex(moduleInfo => moduleInfo._id === updatedPage.moduleId);
          state.selectedPathway.modules[newModuleIndex].pages.push(updatedPage);
        }
      }

      state.updatePageSuccess = true;
    },
    [updatePage.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.updatePageSuccess = false;
      state.updatePageFail = true;
    },

    // Get All Quiz Async Reducers
    [getAllQuiz.pending]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getAllQuiz.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.allTemplateQuiz = payload;
    },
    [getAllQuiz.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },

    // Create Module Async Reducers
    [createModule.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.addModuleSuccess = false;
      state.addModuleFail = false;
    },
    [createModule.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // Updating selectedPathway to have the newly added module.
      const updatedSelectedPathway = {
        ...state.selectedPathway,
        modules: [...state.selectedPathway.modules, payload.createdModule]
      };
      state.selectedPathway = updatedSelectedPathway;
      state.addModuleSuccess = true;
    },
    [createModule.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.addModuleFail = true;
    },

    // Update Module Async Reducers
    [updateModule.pending]: (state) => {
      state.error = null;
      state.loading = true;
      state.updateModuleSuccess = false;
      state.updateModuleFail = false;
    },
    [updateModule.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      const updatedModule = payload.data;
      const moduleIndex = state.selectedPathway.modules
        .findIndex(moduleInfo => moduleInfo._id === updatedModule._id);

      state.selectedPathway.modules[moduleIndex].title = updatedModule.title;
      state.selectedPathway.modules[moduleIndex].sessionName = updatedModule.sessionName;
      state.selectedPathway.modules[moduleIndex].estimatedCompletion = updatedModule.estimatedCompletion;
      state.selectedPathway.modules[moduleIndex].description = updatedModule.description;
      state.selectedPathway.modules[moduleIndex].orderNumber = updatedModule.orderNumber;
      state.updateModuleSuccess = true;
    },
    [updateModule.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.updateModuleFail = true;
    },
  },
});

export default pathwaySlice.reducer;

export {
  createPage,
  createPathway,
  getPathwayById,
  selectPathway
};

export const {
  resetDraftPathway,
  resetSuccessAdd,
  resetDeleteModuleSuccess,
  resetSuccessUpdate,
  resetError,
  resetDeletePathwaySuccess,
  resetSuccessPathwayGet,
  resetDeletePageSuccess,
  resetAddModuleFlags,
  resetAddPageFlags,
  resetUpdatePageFlags,
  resetUpdateModuleFlags,
  resetSelectedPathway,
  sortModulesInSelectedPathway
} = pathwaySlice.actions;
