import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { useState } from "react";
import globals from "../../../styles/Globals.module.css";
import tables from "../../../styles/Tables.module.css";
import styles from "../ManageProgramModule.module.css";

const icon = lightBlue[900];

const UnusedModuleBlock = () => {
  const [order, setOrder] = useState("");

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  };

  return (
    <tr className={`${globals.flex} ${tables.table_module} ${styles.moduleBox} ${tables.table_align_center}`}>
      <td>
        <FormControl sx={{ minWidth: 100, maxHeight: 50 }}>
          <InputLabel>
            Order
          </InputLabel>
          <Select
            value={order}
            onChange={handleOrderChange}
            autoWidth
            label="Order"
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
      </td>
      <td>What is Anxiety?</td>
      <td>
        <IconButton
          color={icon}
          aria-label="complete"
          disabled
        >
          <CheckCircleOutlineIcon />
        </IconButton>
      </td>
      <td>AX</td>
      <td className={`${globals.flex} ${globals.actions}`}>
        <IconButton
          color={icon}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color={icon}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
};

export default UnusedModuleBlock;