import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {
  PAGE_CONTENT_TYPE,
  PAGE_TYPE_TO_TAB_VALUE,
  TAB_VALUE_TO_PAGE_TYPE
} from "constants/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetAddPageFlags, resetUpdatePageFlags, selectPathway } from "reducers/PathwayReducer";
import { createPage, updatePage } from "reducers/actions/PathwayActions";
import btnStyles from "styles/Button.module.css";
import PersonaTabContent from "./ContentCreation/PersonaTabContent";
import QuizTabContent from "./ContentCreation/QuizTabContent";
import WrittenTabContent from "./ContentCreation/WrittenTabContent";
import PageTabContent from "./PageTabContent";
import QuizTemplates from "./QuizTemplates";

const PageForm = ({ selectedModule, pageTitle, selectedPage }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    addPageFail,
    addPageSuccess,
    selectedPathway,
    updatePageSuccess,
    updatePageFail } = useSelector(selectPathway);

  // State for Form Fields
  const [title, setTitle] = useState(selectedPage ? selectedPage.title : "");
  const [sessionName, setSessionName] = useState(
    selectedPage ? selectedPage.sessionName : "");
  const [selectModule, setSelectModule] = useState(
    selectedModule?._id ? selectedModule._id : "None");
  const [estimatedCompletion, setEstimatedCompletion] = useState(
    selectedPage ? selectedPage.estimatedCompletion : 1);
  const [description, setDescription] = useState(
    selectedPage ? selectedPage.description : "");
  const [pageContent, setPageContent] = useState(() => {
    if (selectedPage) {
      if (selectedPage.type === PAGE_CONTENT_TYPE.WRITTEN) return selectedPage.writtenContent;
      if (selectedPage.type === PAGE_CONTENT_TYPE.QUIZ_SURVEY) return selectedPage.quizContent;
      if (selectedPage.type === PAGE_CONTENT_TYPE.PERSONA) return selectedPage.personaContent;
    } else {
      return null;
    }
  });

  // Tab state for the current page content type.
  const [tabValue, setTabValue] = useState(
    selectedPage ? PAGE_TYPE_TO_TAB_VALUE[selectedPage.type] : 0);

  // Function to handle tab change
  const handleTabChange = (newIndex) => {
    setTabValue(newIndex);
  };

  // Set the tab value based on the selected page"s content type
  const setTabValueForPage = () => {
    if (selectedPage && PAGE_TYPE_TO_TAB_VALUE[selectedPage.type] !== undefined) {
      setTabValue(PAGE_TYPE_TO_TAB_VALUE[selectedPage.type]);
    } else {
      setTabValue(0);
    }
  };

  useEffect(() => {
    setTabValueForPage();
  }, [selectedPage]);

  const handlePageContentChange = (content) => {
    setPageContent(content);
  };

  const handleSubmit = () => {
    if (selectedPage) {
      handlePageUpdate();
    } else {
      createNewPage();
    }
  };

  const handlePageUpdate = () => {
    const type = TAB_VALUE_TO_PAGE_TYPE[tabValue];
    const page = {
      _id: selectedPage._id,
      moduleId: selectModule,
      title,
      sessionName,
      description,
      estimatedCompletion,
      type,
      orderNumber: selectedPage.orderNumber,
    };

    if (type === PAGE_CONTENT_TYPE.WRITTEN) {
      dispatch(updatePage({ ...page, writtenContent: pageContent }));
    }

    if (type === PAGE_CONTENT_TYPE.QUIZ_SURVEY) {
      dispatch(updatePage({ ...page, quizContent: pageContent }));
    }

    if (type === PAGE_CONTENT_TYPE.PERSONA) {
      dispatch(updatePage({ ...page, personaContent: pageContent }));
    }
  };

  const createNewPage = () => {
    const type = TAB_VALUE_TO_PAGE_TYPE[tabValue];
    const page = {
      moduleId: selectModule,
      title,
      sessionName,
      description,
      estimatedCompletion,
      type,
      orderNumber: selectedModule ? selectedModule.pages.length + 1 : 1,
    };

    if (type === PAGE_CONTENT_TYPE.WRITTEN) {
      dispatch(createPage({ ...page, writtenContent: pageContent }));
    }

    if (type === PAGE_CONTENT_TYPE.QUIZ_SURVEY) {
      dispatch(createPage({ ...page, quizContent: pageContent }));
    }

    if (type === PAGE_CONTENT_TYPE.PERSONA) {
      dispatch(createPage({ ...page, personaContent: pageContent }));
    }
  };

  useEffect(() => {
    if (addPageSuccess) {
      toast.success("Page created successfully", { toastId: "page-success" });
      dispatch(resetAddPageFlags());
      navigate(`/pathway-builder/${selectedPathway._id}/create-pathway`);
    }

    if (addPageFail) {
      toast.error("An error occured", { toastId: "page-error" });
      dispatch(resetAddPageFlags());
    }

    if (updatePageSuccess) {
      toast.success("Page updated successfully", { toastId: "page-update-success" });
      dispatch(resetUpdatePageFlags());
      navigate(`/pathway-builder/${selectedPathway._id}/create-pathway`);
    }

    if (updatePageFail) {
      toast.error("An error occured when updating page", { toastId: "page-error" });
      dispatch(resetUpdatePageFlags());
    }
  }, [addPageFail, addPageSuccess, updatePageSuccess, updatePageFail]);

  return (
    <>
      <h3>{pageTitle} Page for {selectedModule.title}</h3>
      <Grid spacing={2} sx={{ mt: 1 }} container>
        <Grid item xs={6}>
          <TextField
            id="page-title"
            label="Enter page title"
            value={title}
            fullWidth
            size="small"
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined" required />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth rules={{ required: "Please select a module" }}>
            <InputLabel size="small" id="page-order-number-label">Module</InputLabel>
            <Select
              size="small"
              labelId="page-order-number-label"
              id="demo-simple-select"
              value={selectModule.toString()}
              label="Age"
              required
              defaultValue={selectModule?.toString()}
              onChange={(e) => setSelectModule(e.target.value)}>
              {
                selectedPathway.modules.map(moduleData => (
                  <MenuItem
                    key={moduleData._id.toString()}
                    value={moduleData._id.toString()}>
                    {moduleData.orderNumber}. {moduleData.title}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 1 }} spacing={2} container>
        <Grid item xs={6}>
          <TextField
            id="page-session-name"
            label="Enter session name"
            fullWidth
            size="small"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            variant="outlined"
            required />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="page-est-completion"
            label="Enter estimated completion"
            fullWidth
            size="small"
            type="number"
            helperText="Enter days to complete this page"
            value={estimatedCompletion}
            onChange={(e) => setEstimatedCompletion(parseInt(e.target.value))}
            variant="outlined"
            required />
        </Grid>
      </Grid>
      <Grid sx={{ mt: 1 }} spacing={2} container>
        <Grid item xs={12}>
          <FormControl fullWidth
            variant="outlined">
            <TextField
              id="page-description"
              label="Description for page"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <PageTabContent tabValue={tabValue} handleTabChange={handleTabChange} />
          {tabValue === 0
            && <WrittenTabContent
              selectedPage={selectedPage}
              handleChange={handlePageContentChange} />}
          {tabValue === 1
            && <QuizTabContent
              selectedPage={selectedPage}
              handleChange={handlePageContentChange} />}
          {tabValue === 2
            && <PersonaTabContent
              selectedPage={selectedPage}
              handleChange={handlePageContentChange} />}
          {tabValue === 3
            && <QuizTemplates
              selectedPage={selectedPage}
              handleChange={handlePageContentChange} />}
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }} container>
        <Button
          className={`${btnStyles.btn_primary}`}
          sx={{
            textTransform: "none",
            padding: "8px 50px",
            mr: 2
          }}
          variant="contained"
          onClick={handleSubmit}
          disableElevation>
          {selectedPage ? "Update page" : "Create page"}
        </Button>
        <Button
          className={`${btnStyles.btn_secondary}`}
          sx={{
            textTransform: "none",
            padding: "8px 50px"
          }}
          variant="contained"
          disableElevation
          onClick={() => navigate(`/pathway-builder/${selectedPathway._id}/create-pathway`)}>
          Cancel
        </Button>
      </Grid>
    </>
  );
};

export default PageForm;
