// TODO: This screen is not used anymore can be deleted.
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Checkbox, FormControl, FormControlLabel, FormLabel, Input, Link, Radio, RadioGroup, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Editor from "../../components/TextEditor";
import btn from "../../styles/Button.module.css";
import globals from "../../styles/Globals.module.css";
import fields from "../../styles/InputFields.module.css";
import styles from "../../styles/screens/PathwayBuilder.module.css";

const QuizQList = () => {
  const navigate = useNavigate();

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      onClick={() => navigate("/pathway-builder/create-pathway")}
    >
      Pathway
    </Link>,
    <Link
      underline="hover"
      key="2"
      onClick={() => navigate("/pathway-builder/create-module")}
    >
      Module
    </Link>,
    <Link
      underline="hover"
      key="3"
      onClick={() => navigate("/pathway-builder/create-page")}
    >
      Page
    </Link>,
    <Link
      underline="hover"
      key="4"
      onClick={() => navigate("/pathway-builder/manage-contents")}
    >
      Contents
    </Link>,
    <Link
      underline="hover"
      key="5"
      onClick={() => navigate("/pathway-builder/create-quiz")}
    >
      Quiz
    </Link>,
    <Typography
      key="6"
    >
      List Question
    </Typography>
  ];

  return (
    <div className={`${globals.flex} ${globals.flex_col} ${styles.createPathwayCon}`}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <h1>Quiz Title</h1>
      <div className={`${globals.flex} ${globals.width_100} ${styles.createQuizCon}`}>
        <div className={`${globals.flex} ${globals.flex_wrap} ${globals.flex_col} ${globals.gap_1} ${globals.max_desktop} ${globals.width_100}`}>
          <div className={globals.width_100}>
            <FormControl>
              <FormLabel>
                Select a type of question to add:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="quiz-type-buttons-group"
                defaultValue="list"
              >
                <FormControlLabel
                  value="radio"
                  control={<Radio />}
                  label="Radio"
                  onClick={() => navigate("/pathway-builder/create-quiz/radio-question")}
                />
                <FormControlLabel
                  value="slider"
                  control={<Radio />}
                  label="Slider"
                  onClick={() => navigate("/pathway-builder/create-quiz/slider-question")}
                />
                <FormControlLabel
                  value="written"
                  control={<Radio />}
                  label="Written"
                  onClick={() => navigate("/pathway-builder/create-quiz/written-question")}
                />
                <FormControlLabel
                  value="list"
                  control={<Radio />}
                  label="List"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={`${globals.flex} ${globals.flex_col} ${styles.quizQuestionCon}`}>
            <div>
              <h2>List Question Components</h2>
              <h3>Question Title:</h3>
              <Input
                className={fields.input_fields}
                placeholder="Enter Question Title"
                variant="outlined"
              />
            </div>
            <div className={`${globals.flex} ${styles.checkBoxCon}`}>
              <FormControlLabel
                control={<Checkbox
                  value="read only"
                  inputProps={{
                    "title": "Read Only",
                    "aria-label": "Read Only",
                    "aria-labelledby": "Read Only",
                  }}
                />}
                label="Read Only"
              />
              <FormControlLabel
                control={<Checkbox
                  value="required"
                  inputProps={{
                    "title": "Required",
                    "aria-label": "Required",
                    "aria-labelledby": "Required",
                  }}
                />}
                label="Required"
              />
            </div>
            <div>
              <h3>List Item Placeholder:</h3>
              <Input
                className={fields.input_fields}
                placeholder="Enter List Item Placeholder"
                variant="outlined"
              />
            </div>
            <div>
              <h3>List Item Title:</h3>
              <Input
                className={fields.input_fields}
                placeholder="Enter List Item Title"
                variant="outlined"
              />
            </div>
            <div>
              <h3>Question Tip:</h3>
              <Editor />
            </div>
            <button
              type="submit"
              className={`${btn.btn_primary} ${btn.btn_save} ${globals.width_100} ${styles.addQuestion}`}
            >
              Add Quiz Question
            </button>
            <div className={`${globals.flex} ${globals.space_between} ${globals.gap_1}`}>
              <button
                type="submit"
                className={`${btn.btn} ${btn.btn_primary_outline} ${btn.btn_save} ${globals.width_100}`}
                onClick={() => navigate("/pathway-builder/manage-contents")}
              >
                Save Quiz
              </button>
              <button
                type="button"
                className={`${btn.btn} ${btn.btn_secondary} ${btn.btn_save} ${globals.width_100}`}
                onClick={() => navigate("/pathway-builder/manage-contents")}
              >
                Back to Contents
              </button>
            </div>
          </div>
        </div>
        <div className={`${globals.width_100} ${styles.createdQuestionsCon}`}>
          <h3>Questions:</h3>
          <div>No questions so far!</div>
        </div>
      </div>
    </div>
  );
};

export default QuizQList;