import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import { QUIZ_QUESTION_TYPES_ENUM } from "constants/constants";

const AddedQuestions = ({ questions, onEdit, onDelete }) => {

  // Get corresponding question title.
  const getQuestionTitle = (question) => {
    const { type } = question;
    if (type === QUIZ_QUESTION_TYPES_ENUM.WRITTEN && question.written != null)
      return question.written.title;

    if (type === QUIZ_QUESTION_TYPES_ENUM.RADIO && question.radio != null)
      return question.radio.title;

    if (type === QUIZ_QUESTION_TYPES_ENUM.LIST && question.list != null)
      return question.list.title;

    if (type === QUIZ_QUESTION_TYPES_ENUM.SLIDER && question.slider != null)
      return question.slider.title;

    return "TITLE_NOT_FOUND";
  };

  return (
    <div>
      <h3 style={{ marginBottom: "1em" }}>Added Questions:</h3>
      {
        questions.length === 0 ? (<div>No questions so far!</div>) : (<></>)
      }
      {
        questions.map((question, index) => (
          <Paper sx={{ mb: 1 }} key={index}>
            <Grid
              justifyContent="space-between"
              alignItems="center"
              container>
              <Grid xs={6} item>
                <Typography style={{ marginLeft: "1em" }}>
                  {getQuestionTitle(question)}
                </Typography>
              </Grid>
              <Grid xs={6} style={{ textAlign: "right" }} item>
                <IconButton aria-label="delete" size="large" onClick={() => onEdit(question)}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" color="error" size="large" onClick={() => onDelete(question)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))
      }
    </div>
  );
};

export default AddedQuestions;
