import globals from "../../styles/Globals.module.css";
import tables from "../../styles/Tables.module.css";
import NotesEntryDetails from "./NotesEntryDetails";

const NotesBox = () => {
  return (
    <table className={globals.width_100}>
      <tr className={`${globals.flex} ${tables.table_headings}`}>
        <th>Date</th>
        <th>Note Title</th>
        <th>Topic</th>
        <th>Actions</th>
      </tr>
      <NotesEntryDetails />
    </table>
  );
};

export default NotesBox;