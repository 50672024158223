import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ConfirmationAlert from "components/Alerts/ConfirmationAlert";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetDeleteModuleSuccess, selectPathway } from "reducers/PathwayReducer";
import { deleteModule, getAllModules } from "reducers/actions/PathwayActions";
import globals from "../../styles/Globals.module.css";

const ActivePathwayTable = ({ modules }) => {

  const dispatch = useDispatch();
  const { deleteModuleSuccess } = useSelector(selectPathway);

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [moduleToDelete, setModuleToDelete] = useState(null);

  // Styling for table.
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0b7ea1",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // Function to handle module deletion
  const handleModuleDelete = (moduleId) => {
    setModuleToDelete(moduleId);
    setIsAlertOpen(true);
  };

  // useEffect hook to react to changes in 'deleteModuleSuccess'
  useEffect(() => {
    // Display a success toast when 'deleteModuleSuccess' is true
    if (deleteModuleSuccess) {
      toast.success("Module deleted successfully", { toastId: "delete-module-success" });

      // Dispatch the 'resetDeleteModuleSuccess' action to reset the 'deleteModuleSuccess' state
      dispatch(resetDeleteModuleSuccess());

      // Getting the list of all modules after successfull delete.
      dispatch(getAllModules());
    }
  }, [deleteModuleSuccess]);

  // Function to handle alert delete cancel.
  const handleDeleteCancel = () => {
    setModuleToDelete(null);
    setIsAlertOpen(false);
  };

  // Function to confirm deletion for alert.
  const handleDeleteConfirmation = () => {
    if (moduleToDelete) {
      dispatch(deleteModule(moduleToDelete));
    }
  };

  return (
    <div>
      <h3 style={{ marginTop: "2em", marginBottom: "10px" }} className={globals.mb_0}>Pathway Modules</h3>
      <ConfirmationAlert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onCancel={handleDeleteCancel}
        onAgree={handleDeleteConfirmation}
        title="Delete Module Confirmation"
        content="Are you sure you want to delete the module?" />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Draft Pathway Modules">
          <TableHead>
            <TableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="right">Session Name</StyledTableCell>
              <StyledTableCell align="right">Est. Completion</StyledTableCell>
              <StyledTableCell align="right">Date Created</StyledTableCell>
              <StyledTableCell style={{ width: "15%" }} align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              modules?.map(moduleData => (
                <TableRow
                  key={moduleData._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {moduleData.title}
                  </TableCell>
                  <TableCell align="right">{moduleData.sessionName}</TableCell>
                  <TableCell align="right">{moduleData.estimatedCompletion} {moduleData.estimatedCompletion === 1 ? " Day" : " Days"}</TableCell>
                  <TableCell align="right">{moment(moduleData.createdAt).format("MMM Do YY")}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleModuleDelete(moduleData._id)}
                      size="small">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActivePathwayTable;