import { Input } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetSelectedPathway, selectPathway } from "reducers/PathwayReducer";
import { getAllModules, getAllPathways } from "reducers/actions/PathwayActions";
import ActivePathwayTable from "../components/PathwayBuilderModules/ActivePathwayTable";
import DraftPathwayTable from "../components/PathwayBuilderModules/DraftPathwayTable";
import btn from "../styles/Button.module.css";
import globals from "../styles/Globals.module.css";
import fields from "../styles/InputFields.module.css";
import styles from "../styles/screens/PathwayBuilder.module.css";

const PathwayBuilder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathways, modules } = useSelector(selectPathway);

  // Getting all pathway on page load.
  useEffect(() => {
    dispatch(getAllPathways());
    dispatch(getAllModules());
  }, [dispatch]);

  // Resetting selected pathway before starting to create a new pathway.
  const handleCreatePathway = () => {
    dispatch(resetSelectedPathway());
    navigate("/pathway-builder/create-pathway");
  };

  return (
    <div className={`${globals.flex} ${styles.pathwayBuilderCon}`}>
      <div className={`${globals.flex} ${styles.pathwayContent} ${globals.flex_col} ${globals.max_desktop} ${globals.width_100}`}>
        <DraftPathwayTable pathways={pathways} />
        <button
          className={`${btn.btn} ${btn.btn_primary} ${globals.flex} ${styles.btnPathwayBuilder}`}
          onClick={handleCreatePathway}
        >
          Create Pathway
        </button>
        <div className={fields.sort_fields}>
          <div className={fields.input_con}>
            <h3>Filter by:</h3>
            <Input
              className={fields.input_fields}
              placeholder="Filter by Title, Session Name ..."
            />
          </div>
          <div className={fields.input_con}>
            <h3>Search:</h3>
            <Input
              className={fields.input_fields}
              placeholder="Enter Pathway Module Name"
            />
          </div>
        </div>
        {modules && (<ActivePathwayTable modules={modules} />)}
      </div>
    </div>
  );
};

export default PathwayBuilder;