import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Checkbox,
  FormControlLabel,
  Input
} from "@mui/material";
import ObjectID from "bson-objectid";
import Editor from "components/TextEditor";
import { QUIZ_QUESTION_TYPES_ENUM } from "constants/constants";
import { useState } from "react";
import btn from "styles/Button.module.css";
import globals from "styles/Globals.module.css";
import fields from "styles/InputFields.module.css";
import styles from "styles/screens/PathwayBuilder.module.css";

const RadioQuestion = ({ onSave, question }) => {

  const { radio } = question || { radio: null };

  // State for form fields
  const [title, setTitle] = useState(radio?.title || "");
  const [isReadOnly, setIsReadOnly] = useState(radio?.isReadOnly ? radio.isReadOnly : false);
  const [isRequired, setIsRequired] = useState(radio?.isRequired ? radio.isRequired : false);
  const [tip, setTip] = useState(radio?.tip || "");
  const [options, setOptions] = useState(radio?.options || []);
  const [currentOption, setCurrentOption] = useState("");
  const [currentOptionDesc, setCurrentOptionDesc] = useState("");

  // Add a new option to the options array
  const handleAddOptionClick = () => {
    setOptions((prevOptions) => [
      ...prevOptions,
      { value: currentOption, description: currentOptionDesc, isCorrect: false },
    ]);
    setCurrentOption("");
    setCurrentOptionDesc("");
  };

  // Remove an option from the options array
  const handleRemoveOptionClick = (index) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.filter((_, i) => i !== index);
      return updatedOptions;
    });
  };

  // Toggle isCorrect property for an option.
  const handleIsCorrectOptionClick = (index) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option, i) => {
        if (i === index) {
          return { ...option, isCorrect: !option.isCorrect };
        }
        return option;
      });
      return updatedOptions;
    });
  };

  // Method to save the added quiz.
  const handleAddQuizQuestionClick = () => {
    const radioQuestion = {
      title,
      isReadOnly,
      isRequired,
      tip,
      options,
    };

    onSave({
      radio: radioQuestion,
      _id: question ? question._id : ObjectID(),
      type: QUIZ_QUESTION_TYPES_ENUM.RADIO,
    });

    resetFormFields();
  };

  // Method to reset form fields.
  const resetFormFields = () => {
    setTitle("");
    setIsReadOnly(false);
    setIsRequired(false);
    setTip("");
    setOptions([]);
  };

  // JSX for rendering options
  const RenderOptions = () =>
    options.map((option, index) => (
      <div key={index} className={`${globals.flex} ${globals.gap_1}`}>
        <div className={styles.optionValueCon}>
          <h4>New Option Value:</h4>
          <Input
            className={fields.input_fields}
            placeholder="Enter Value"
            variant="outlined"
            value={option.value}
            disabled
          />
        </div>
        <div className={globals.width_100}>
          <h4>New Option Description:</h4>
          <div className={`${globals.flex} ${styles.optionDescCon}`}>
            <Input
              className={fields.input_fields}
              placeholder="Enter Description"
              variant="outlined"
              value={option.description}
              disabled
            />
            <RemoveCircleIcon
              onClick={() => handleRemoveOptionClick(index)}
              className={globals.icon_primary}
              fontSize="large" />
            <CheckIcon onClick={() => handleIsCorrectOptionClick(index)}
              className={option.isCorrect ? globals.icon_green : globals.icon_primary}
              fontSize="large" />
          </div>
        </div>
      </div>
    ));

  return (
    <div className={`${globals.flex} ${globals.flex_col} ${styles.quizQuestionCon}`}>
      <div>
        <h2>Radio Question Components</h2>
        <h3>Question Title:</h3>
        <Input
          className={fields.input_fields}
          placeholder="Enter Question Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={`${globals.flex} ${styles.checkBoxCon}`}>
        <FormControlLabel
          control={<Checkbox
            checked={isReadOnly}
            onChange={() => setIsReadOnly(prevState => !prevState)}
          />}
          label="Read Only"
        />
        <FormControlLabel
          control={<Checkbox
            checked={isRequired ? isRequired : false}
            onChange={() => setIsRequired(prevState => !prevState)}
          />}
          label="Required"
        />
      </div>
      <div>
        <h3>Question Tip:</h3>
        <Editor value={tip} onChange={setTip} />
      </div>
      <div className={`${styles.optionsBox} ${globals.width_100}`}>
        <h3>Options</h3>
        {RenderOptions()}
        <div className={`${globals.flex} ${globals.gap_1}`}>
          <div className={styles.optionValueCon}>
            <h4>New Option Value:</h4>
            <Input
              className={fields.input_fields}
              placeholder="Enter Value"
              variant="outlined"
              value={currentOption}
              onChange={(e) => setCurrentOption(e.target.value)}
            />
          </div>
          <div className={globals.width_100}>
            <h4>New Option Description:</h4>
            <div className={`${globals.flex} ${styles.optionDescCon}`}>
              <Input
                className={fields.input_fields}
                placeholder="Enter Description"
                variant="outlined"
                value={currentOptionDesc}
                onChange={(e) => setCurrentOptionDesc(e.target.value)}
              />
              <AddCircleIcon
                className={globals.icon_primary}
                fontSize="large"
                onClick={handleAddOptionClick}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className={`${btn.btn_primary} ${btn.btn_save} ${globals.width_100} ${styles.addQuestion}`}
        onClick={handleAddQuizQuestionClick}
      >
        Add Quiz Question
      </button>
    </div>
  );
};

export default RadioQuestion;
