import styles from "../styles/screens/Chat.module.css";

const Chat = () => {
  return (
    <div className={styles.chatCon}>
      <h1>Chat</h1>
    </div>
  );
};

export default Chat;