import {
  Checkbox,
  FormControlLabel,
  Input
} from "@mui/material";
import ObjectID from "bson-objectid";
import { QUIZ_QUESTION_TYPES_ENUM } from "constants/constants";
import { useState } from "react";
import ReactQuill from "react-quill";
import btn from "styles/Button.module.css";
import globals from "styles/Globals.module.css";
import fields from "styles/InputFields.module.css";
import styles from "styles/screens/PathwayBuilder.module.css";

const SliderQuestion = ({ onSave, question }) => {

  const { slider } = question || { slider: null };

  // State for input forms.
  const [title, setTitle] = useState(slider?.title || "");
  const [isReadOnly, setIsReadOnly] = useState(slider?.isReadOnly ? slider.isReadOnly : false);
  const [isRequired, setIsRequired] = useState(slider?.isRequired ? slider.isRequired : false);
  const [tip, setTip] = useState(slider?.tip || "");
  const [min, setMin] = useState(slider?.min ? slider.min : 0);
  const [max, setMax] = useState(slider?.max ? slider.max : 100);
  const [step, setStep] = useState(slider?.step ? slider.step : 10);

  // Function to handle question add.
  const handleQuestionAdd = () => {
    const slider = {
      title,
      isReadOnly,
      isRequired,
      tip,
      min,
      max,
      step,
    };

    onSave({
      slider,
      _id: question ? question._id : ObjectID(),
      type: QUIZ_QUESTION_TYPES_ENUM.SLIDER,
    });
  };

  return (
    <div className={`${globals.flex} ${globals.flex_wrap} ${globals.flex_col} ${globals.gap_1} ${globals.max_desktop} ${globals.width_100}`}>
      <div>
        <h2>Slider Question Components</h2>
        <h3>Question Title:</h3>
        <Input
          className={fields.input_fields}
          placeholder="Enter Question Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className={`${globals.flex} ${styles.checkBoxCon}`}>
        <FormControlLabel
          control={<Checkbox
            checked={isReadOnly}
            onChange={() => setIsReadOnly(prevState => !prevState)}
          />}
          label="Read Only"
        />
        <FormControlLabel
          control={<Checkbox
            checked={isRequired}
            onChange={() => setIsRequired(prevState => !prevState)}
          />}
          label="Required"
        />
      </div>
      <h3>Slider Minimum:</h3>
      <Input
        className={fields.input_fields}
        placeholder="Enter Min Slider Value"
        variant="outlined"
        type="number"
        value={min}
        onChange={(e) => setMin(e.target.value)}
      />
      <h3>Slider Maximum:</h3>
      <Input
        className={fields.input_fields}
        placeholder="Enter Max Slider Value"
        variant="outlined"
        type="number"
        value={max}
        onChange={(e) => setMax(e.target.value)}
      />
      <h3>Slider Step Rate:</h3>
      <Input
        className={fields.input_fields}
        placeholder="Enter Step Rate"
        variant="outlined"
        type="number"
        value={step}
        onChange={(e) => setStep(e.target.value)}
      />
      <div>
        <h3>Question Tip:</h3>
        <ReactQuill value={tip} onChange={setTip} />
      </div>
      <button
        type="submit"
        onClick={handleQuestionAdd}
        className={`${btn.btn_primary} ${btn.btn_save} ${globals.width_100} ${styles.addQuestion}`}
      >
        Add Quiz Question
      </button>
    </div>
  );
};

export default SliderQuestion;
