import SideNavbar from "../../components/SideNavbar";
import SurveyBox from "../../components/Surveys";
import globals from "../../styles/Globals.module.css";
import styles from "../../styles/screens/clients/Surveys.module.css";

const Surveys = () => {
  return (
    <div className={globals.flex}>
      <SideNavbar />
      <div className={`${globals.clients_content} ${styles.content}`}>
        {/* Each survey separated into its own section */}
        <div className={`${globals.width_100} ${styles.surveyResultsCon}`}>
          <h2>Survey #1 Name</h2>
          <SurveyBox />
        </div>
        <div className={`${globals.width_100} ${styles.surveyResultsCon}`}>
          <h2>Survey #2 Name</h2>
          <SurveyBox />
        </div>
      </div>
    </div>
  );
};

export default Surveys;