import { Avatar, Input } from "@mui/material";
import { PERSONA_IMAGE_TYPES } from "constants/constants";
import logo from "psystem_coaching.svg";
import { useState } from "react";
import ReactQuill from "react-quill";
import globals from "styles/Globals.module.css";
import fields from "styles/InputFields.module.css";
import styles from "styles/screens/PathwayBuilder.module.css";

const PersonaTabContent = ({ selectedPage, handleChange }) => {

  const { personaContent } = selectedPage ? selectedPage : { personaContent: null };

  const [title, setTitle] = useState(personaContent ? personaContent.title : "");
  const [name, setName] = useState(personaContent ? personaContent.name : "");
  const [writtenContent, setWrittenContent] = useState(
    personaContent ? personaContent.writtenContent : "");
  const [profileImage, setProfileImage] = useState(
    personaContent ? personaContent.profileImage : "");

  // Getting style for avatar based on selection.
  const getPersonaAvatarStyle = (persona) => {
    if (persona === profileImage) {
      return { width: 56, height: 56, border: "#e0f8ff 0.30rem solid" };
    } else {
      return { width: 56, height: 56, border: "#fff 0.30rem solid" };
    }
  };

  const handlePersonaFormChange = (data) => {
    handleChange({ title, name, writtenContent, profileImage, ...data });

    if (data.title) setTitle(data.title);
    if (data.name) setName(data.name);
    if (data.writtenContent) setWrittenContent(data.writtenContent);
    if (data.profileImage) setProfileImage(data.profileImage);
  };

  return (
    <div>
      <div className={`${globals.flex} ${globals.flex_col} ${globals.gap_1}`}>
        <div className={`${globals.flex} ${globals.flex_wrap} ${globals.gap_1}`}>
          <div className={fields.input_con}>
            <h3>Title:</h3>
            <Input
              className={fields.input_fields}
              placeholder="Enter Title"
              variant="outlined"
              value={title}
              onChange={(e) => handlePersonaFormChange({ title: e.target.value })}
            />
          </div>
          <div className={fields.input_con}>
            <h3>Persona Name:</h3>
            <Input
              className={fields.input_fields}
              placeholder="Select"
              variant="outlined"
              value={name}
              onChange={(e) => handlePersonaFormChange({ name: e.target.value })}
            />
          </div>
          <div className={fields.input_con}>
            <h3>Written Content:</h3>
            <ReactQuill
              value={writtenContent}
              onChange={(e) => handlePersonaFormChange({ writtenContent: e })} />
          </div>
          <div className={fields.input_con}>
            <h3>Images:</h3>
            <div className={`${globals.flex} ${globals.flex_wrap} ${styles.imagesCon}`}>
              {Object.keys(PERSONA_IMAGE_TYPES).map(key => (
                <Avatar
                  key={key}
                  alt="logo"
                  src={logo}
                  onClick={() => handlePersonaFormChange({ profileImage: key })}
                  sx={() => getPersonaAvatarStyle(key)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaTabContent;
