import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmationAlert = ({
  isOpen,              // Indicates whether the confirmation dialog is open or not
  onClose,             // Callback function to close the confirmation dialog
  content,             // Content to be displayed within the dialog
  agreeBtnText,        // Text for the agree/confirm button
  disagreeBtnText,     // Text for the disagree/cancel button
  title,               // Title of the confirmation dialog
  onAgree,             // Callback function triggered when the agree/confirm button is clicked
  onCancel             // Callback function triggered when the disagree/cancel button is clicked
}) => {

  // Handle close action
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{disagreeBtnText}</Button>
          <Button onClick={onAgree} autoFocus>
            {agreeBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// Setting default values for props
ConfirmationAlert.defaultProps = {
  isOpen: false,
  onClose: () => { },
  content: "",
  agreeBtnText: "Okay",
  disagreeBtnText: "Cancel",
  title: "Confirmation",
  onAgree: () => { },
  onCancel: () => { }
};

export default ConfirmationAlert;
