import { Alert, Grid, Input, Link, MenuItem, Paper, Select, Stack } from "@mui/material";
import { URLS } from "constants/url";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "reducers/UserReducer";
import { signup } from "reducers/actions/UserActions";
import btn from "styles/Button.module.css";
import fields from "styles/InputFields.module.css";
import { checkPasswordStrength, validateEmail } from "utilities/validator";

const Signup = () => {
  // Dispatch and navigation hooks.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State variables for input fields
  const [userType, setUserType] = useState("COACH");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [accessCode, setAccessCode] = useState("");

  // Error handling state
  const [errorMessage, setErrorMessage] = useState("");

  // Selecting data from Redux store using the useSelector hook
  const { loading, user, error } = useSelector(selectUser);

  // Function for handling the signup action
  const handleSignup = (event) => {
    event.preventDefault();

    // Validating email
    if (!validateEmail(email)) {
      setErrorMessage("Invalid email address");
      return;
    }

    // Validating password strength.
    if (!checkPasswordStrength(password)) {
      setErrorMessage("Password should contain min 8 letter password, with at least a symbol, upper and lower case letters and a number");
      return;
    }

    // Validating password
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    dispatch(signup({ firstName, email, password, userType, accessCode }));
  };

  // Effect hook that triggers when the "user" variable changes
  useEffect(() => {
    if (user) {
      navigate(URLS.HOME);
    }
  }, [user]);

  return (
    <>
      <Grid
        style={{ marginTop: "2em" }}
        container
        justifyContent="center"
        alignItems="center">
        <Grid item xl={4} lg={4} md={6} sm={8} xs={10}>
          <Paper style={{ padding: "2em" }} variant="outlined">
            <form onSubmit={handleSignup}>
              <Stack spacing={2}>
                <h3>Create your account</h3>
                {
                  (error || errorMessage) &&
                  (<Alert
                    severity="error"
                    onClose={() => setErrorMessage("")}>{error ? error : errorMessage}</Alert>)
                }
                <div>
                  <h3>Who are you signing up as?</h3>
                  <Select
                    value={userType}
                    style={{ width: "100%" }}
                    onChange={(e) => setUserType(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"COACH"}>Coach</MenuItem>
                    <MenuItem value={"USER"}>User</MenuItem>
                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                  </Select>
                </div>
                {
                  userType === "COACH" && (
                    <div>
                      <h3>Access Code:</h3>
                      <Input
                        value={accessCode}
                        onChange={(e) => setAccessCode(e.target.value)}
                        type="text"
                        className={fields.input_fields}
                        placeholder="Enter access code"
                      />
                    </div>
                  )
                }
                <div>
                  <h3>First Name:</h3>
                  <Input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    className={fields.input_fields}
                    placeholder="Enter your first name"
                  />
                </div>
                <div>
                  <h3>Email:</h3>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className={fields.input_fields}
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <h3>Password:</h3>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className={fields.input_fields}
                    placeholder="Enter your password"
                  />
                </div>
                <div>
                  <h3>Confirm Password:</h3>
                  <Input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    className={fields.input_fields}
                    placeholder="Enter password again"
                  />
                </div>
                <Grid justifyContent="center" alignItems="center" container>
                  <button
                    type="submit"
                    className={`${btn.btn} ${btn.btn_primary}`}
                    disabled={loading}
                  >
                    Sign Up
                  </button>
                </Grid>
              </Stack>
            </form>
            <span>Already have an account ? <Link
              onClick={() => navigate(URLS.LOGIN)}>Login</Link></span>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Signup;