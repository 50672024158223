import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses
} from "@mui/material";
import { PAGE_CONTENT_TYPE, QUIZ_QUESTION_TYPES_ENUM } from "constants/constants";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPathway } from "reducers/PathwayReducer";
import { getAllQuiz } from "reducers/actions/PathwayActions";

const QuizTemplates = ({ onTemplateSelect }) => {

  const dispatch = useDispatch();

  const { allTemplateQuiz } = useSelector(selectPathway);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0b7ea1",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    dispatch(getAllQuiz());
  }, [dispatch]);

  const addQuiz = (quiz) => {
    onTemplateSelect({ type: PAGE_CONTENT_TYPE.QUIZ_SURVEY, quizContent: quiz });
  };

  const ExplandedDataTable = ({ questions }) => {

    return (
      <TableContainer style={{ borderRadius: 0 }} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 800 }}>Question Title</TableCell>
              <TableCell sx={{ fontWeight: 800 }} align="right">Is Required</TableCell>
              <TableCell sx={{ fontWeight: 800 }} align="right">Is Read Only</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              questions.map((question) => {
                if (question.type === QUIZ_QUESTION_TYPES_ENUM.RADIO && question.radio != null) {
                  return (
                    <TableRow
                      key={question._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {question.radio.title}
                      </TableCell>
                      <TableCell align="right">{question.radio.isRequired ? "Yes" : "No"}</TableCell>
                      <TableCell align="right">{question.radio.isReadOnly ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  );
                }

                if (question.type === QUIZ_QUESTION_TYPES_ENUM.WRITTEN && question.written != null) {
                  return (
                    <TableRow
                      key={question._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {question.written.title}
                      </TableCell>
                      <TableCell align="right">{question.written.isRequired ? "Yes" : "No"}</TableCell>
                      <TableCell align="right">{question.written.isReadOnly ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  );
                }

                if (question.type === QUIZ_QUESTION_TYPES_ENUM.LIST && question.list != null) {
                  return (
                    <TableRow
                      key={question._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {question.list.title}
                      </TableCell>
                      <TableCell align="right">{question.list.isRequired ? "Yes" : "No"}</TableCell>
                      <TableCell align="right">{question.list.isReadOnly ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  );
                }

                if (question.type === QUIZ_QUESTION_TYPES_ENUM.SLIDER && question.slider != null) {
                  return (
                    <TableRow
                      key={question._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {question.slider.title}
                      </TableCell>
                      <TableCell align="right">{question.slider.isRequired ? "Yes" : "No"}</TableCell>
                      <TableCell align="right">{question.slider.isReadOnly ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  );
                }
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const QuizTableRow = ({ quiz }) => {
    const [open, setOpen] = useState(false);

    return (
      <Fragment>
        <Fragment key={quiz._id}>
          <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell component="th" scope="row">
              {quiz.title === undefined ? "No Title" : quiz.title}
            </TableCell>
            <TableCell align="center">{quiz.questions.length}</TableCell>
            <TableCell align="center">
              <IconButton onClick={() => addQuiz(quiz)} color="success" aria-label="add-quiz">
                <AddCircleOutlineIcon />
              </IconButton>
            </TableCell>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </StyledTableRow>
          <TableCell style={{ padding: 0, borderRadius: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <ExplandedDataTable questions={quiz.questions} />
            </Collapse>
          </TableCell>
        </Fragment>
      </Fragment>
    );
  };

  return (
    <div style={{ marginTop: "2em" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Quiz Title</StyledTableCell>
              <StyledTableCell align="center">No of Questions</StyledTableCell>
              <StyledTableCell align="center">Add Quiz ?</StyledTableCell>
              <StyledTableCell align="center">View All Questions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              allTemplateQuiz
              && allTemplateQuiz.map((quiz) => (<QuizTableRow key={quiz._id} quiz={quiz} />))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default QuizTemplates;
