import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Switch, styled } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { useState } from "react";
import globals from "../../../styles/Globals.module.css";
import tables from "../../../styles/Tables.module.css";
import styles from "../ManageProgramModule.module.css";

const icon = lightBlue[900];

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: "",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>")`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>")`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const UsedModuleBlock = () => {
  const [order, setOrder] = useState("");

  const handleOrderChange = (event) => {
    setOrder(event.target.value);
  };

  return (
    <tr className={`${globals.flex} ${tables.table_module} ${styles.moduleBox} ${tables.table_align_center}`}>
      <td>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>
            Order
          </InputLabel>
          <Select
            value={order}
            onChange={handleOrderChange}
            autoWidth
            label="Order"
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
      </td>
      <td className={styles.name}>What is Depression?</td>
      <td>
        <IconButton
          color={icon}
          aria-label="complete"
        >
          <CheckCircleOutlineIcon />
        </IconButton>
      </td>
      <td>DP</td>
      <td>
        <FormControlLabel
          control={<Android12Switch defaultChecked />}
          aria-label="Completed Toggle"
        />
      </td>
      <td>
        <FormControlLabel
          control={<Android12Switch defaultChecked />}
          aria-label="Completed Toggle"
        />
      </td>
    </tr>
  );
};

export default UsedModuleBlock;