import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import ConfirmationAlert from "components/Alerts/ConfirmationAlert";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  resetDeletePathwaySuccess,
  resetSuccessPathwayGet,
  selectPathway
} from "reducers/PathwayReducer";
import { deletePathwayById, getAllPathways, getPathwayById } from "reducers/actions/PathwayActions";

const DraftPathwayTable = ({ pathways }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [pathwayToDelete, setPathwayToDelete] = useState(null);
  const { deletePathwaySuccess, successPathwayGet, selectedPathway } = useSelector(selectPathway);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0b7ea1",
      color: theme.palette.common.white,
      textAlign: "left",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableBodyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
    },
  }));

  // This useEffect hook handles the effects that occur after certain pathway-related actions.
  useEffect(() => {
    // Display a success toast if a pathway was successfully deleted.
    if (deletePathwaySuccess) {
      toast.success("Pathway deleted successfully", { toastId: "pathway-delete-success" });
      dispatch(resetDeletePathwaySuccess());
      dispatch(getAllPathways());
    }

    // Navigate to the pathway creation page upon successful retrieval of pathway data.
    if (successPathwayGet) {
      dispatch(resetSuccessPathwayGet());
      navigate(`/pathway-builder/${selectedPathway._id}/create-pathway`);
    }
  }, [deletePathwaySuccess, successPathwayGet]);

  // Function to handle pathway edit.
  const handleEditClick = (id) => {
    dispatch(getPathwayById(id));
  };

  // Function to delete pathway by id.
  const handleDeleteClick = (id) => {
    setPathwayToDelete(id);
    setIsAlertOpen(true);
  };

  // Function to confirm deletion for alert.
  const handleDeleteConfirmation = () => {
    if (pathwayToDelete) {
      dispatch(deletePathwayById(pathwayToDelete));
    }
  };

  // Function to handle alert delete cancel.
  const handleDeleteCancel = () => {
    setPathwayToDelete(null);
    setIsAlertOpen(false);
  };

  return (
    <Fragment>
      {pathways.length > 0 && (<h3 style={{ marginTop: "2em" }}>All Pathways</h3>)}
      <ToastContainer />
      <ConfirmationAlert
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        onCancel={handleDeleteCancel}
        onAgree={handleDeleteConfirmation}
        title="Delete Pathway Confirmation"
        content="Are you sure you want to delete the pathway?" />
      {
        pathways?.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Draft Pathway Modules">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align="right">Session Name</StyledTableCell>
                  <StyledTableCell style={{ width: "40%" }} align="right">Description</StyledTableCell>
                  <StyledTableCell style={{ width: "15%", textAlign: "right" }}>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pathways?.map((pathway) => (
                  <TableRow
                    key={pathway._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableBodyCell component="th" scope="row">
                      {pathway.title}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell component="th" scope="row">
                      {pathway.isDraft ? (<Chip label="Draft" color="primary" />) : (<Chip label="Completed" color="success" />)}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell align="right">{pathway.sessionName}</StyledTableBodyCell>
                    <StyledTableBodyCell align="right">{pathway.description}</StyledTableBodyCell>
                    <StyledTableBodyCell style={{ textAlign: "right" }}>
                      <IconButton
                        color="info"
                        aria-label="edit"
                        size="small"
                        onClick={() => handleEditClick(pathway._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(pathway._id)}
                        color="error"
                        aria-label="delete"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableBodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
    </Fragment>
  );
};

export default DraftPathwayTable;