import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import globals from "../../styles/Globals.module.css";
import tables from "../../styles/Tables.module.css";
import styles from "../Notes/NotesElements.module.css";

const icon = lightBlue[900];

const NotesEntryDetails = () => {
  return (
    <tr className={`${globals.flex} ${tables.table_module} ${styles.noteBox}`}>
      <div className={`${globals.flex}`}>
        <td className={styles.date}>Jun 1st 2023</td>
        <td className={styles.title}>No Activity</td>
        <td className={styles.topic}>Client Profile Review</td>
        <td className={`${globals.flex} ${globals.actions}`}>
          <IconButton
            color={icon}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color={icon}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </td>
      </div>
      <p className={styles.notePreview}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    </tr>
  );
};

export default NotesEntryDetails;