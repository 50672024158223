import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectPathway, sortModulesInSelectedPathway } from "reducers/PathwayReducer";
import btnStyles from "styles/Button.module.css";
import ModuleListTable from "./ModuleListTable";
import PageListTable from "./PageListTable";

const ModuleList = () => {
  const { selectedPathway } = useSelector(selectPathway);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to handle creating a new page for the last module.
  const handleNewPageClick = () => {
    // Check if there are modules in the selected pathway and there is at least one module.
    if (selectedPathway.modules != null && selectedPathway.modules.length > 0) {
      // Get the list of modules from the selected pathway.
      const listOfModules = selectedPathway.modules;

      // Get the last module in the list.
      const lastModule = listOfModules[listOfModules.length - 1];
      navigate(`/pathway-builder/${lastModule._id}/create-page`);
    } else {
      // Display an error toast if there are no modules in the pathway.
      toast.error("There needs to be at least one module to create a page");
    }
  };

  // Sorting modules after order number change.
  useEffect(() => {
    if (selectedPathway) {
      dispatch(sortModulesInSelectedPathway());
    }
  }, [selectedPathway]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={11} md={11} lg={10} >
        {selectedPathway?.modules.length > 0 ? (
          selectedPathway?.modules.map((moduleInfo) => (
            <div key={moduleInfo._id}>
              <ModuleListTable moduleInfo={moduleInfo} />
              {moduleInfo.pages?.length > 0 ? (
                <div>
                  <Grid container justifyContent="flex-end">
                    <PageListTable moduleId={moduleInfo._id} pages={moduleInfo.pages} />
                  </Grid>
                </div>
              ) : (
                <>
                </>
              )}
            </div>
          ))
        ) : (
          <>

          </>
        )}
        <Grid sx={{ mt: 3, mb: 3 }} container justifyContent="center">
          <Grid container item xs={12} justifyContent="flex-end">
            <Button
              sx={{
                textTransform: "none",
                padding: "8px 50px",
                mr: 2,
              }}
              className={`${btnStyles.btn_secondary}`}
              variant="contained"
              disableElevation
              onClick={() => navigate(`/pathway-builder/${selectedPathway._id}/create-module`)}>
              Add new module
            </Button>
            <Button
              sx={{
                textTransform: "none",
                padding: "8px 50px"
              }}
              className={`${btnStyles.btn_primary}`}
              variant="contained"
              disableElevation
              onClick={handleNewPageClick}>
              Add new page
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModuleList;
