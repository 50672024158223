// Added eslint disable to prevent switch case indentation error.
/* eslint-disable indent */
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup
} from "@mui/material";
import { QUIZ_QUESTION_TYPES } from "constants/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  resetError,
  resetSuccessAdd,
  resetSuccessUpdate,
  selectPathway
} from "reducers/PathwayReducer";
import globals from "styles/Globals.module.css";
import styles from "styles/screens/PathwayBuilder.module.css";
import AddedQuestions from "./QuizContent/AddedQuestions";
import ListQuestion from "./QuizContent/ListQuestion";
import RadioQuestion from "./QuizContent/RadioQuestion";
import SliderQuestion from "./QuizContent/SliderQuestion";
import WrittenQuestion from "./QuizContent/WrittenQuestion";

const QuizTabContent = ({ selectedPage, handleChange }) => {

  const { quizContent } = selectedPage || {};

  const [title, setTitle] = useState(quizContent?.title || "");
  const [questions, setQuestions] = useState(quizContent?.questions || []);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [questionSelectedForEdit, setQuestionSelectedForEdit] = useState(null);

  const dispatch = useDispatch();
  const { successAdd, successUpdate, error } = useSelector(selectPathway);

  // Function to handle question add.
  const handleQuestionAdd = (question) => {
    const updatedQuestions = questions.map((existingQuestion) => {
      if (existingQuestion._id === question._id) {
        return question; // Replace the existing question
      }
      return existingQuestion; // Keep other questions unchanged
    });

    if (!updatedQuestions.some((existingQuestion) => existingQuestion._id === question._id)) {
      // Add the new question if not already present
      updatedQuestions.push(question);
    }

    setQuestions(updatedQuestions);
    setQuestionSelectedForEdit(null);
    setSelectedQuestionType(null);

    handleChange({ title, questions });
  };

  // Function to handle quiz save to api.
  // const handleQuizSave = () => {
  //   const quizContent = {
  //     title,
  //     questions,
  //   };

  //   dispatch(updatePage({
  //     _id: selectedPage._id,
  //     type: PAGE_CONTENT_TYPE.QUIZ_SURVEY,
  //     quizContent,
  //   }));
  // };

  // Function to handle question edit option.
  const handleAddedQuestionEdit = (question) => {
    setQuestionSelectedForEdit(question);
    setSelectedQuestionType(question.type);
  };

  // Function to handle question delete option.
  const handleAddedQuestionDelete = (questionToDelete) => {
    const updatedQuestions = questions.filter(
      (existingQuestion) => existingQuestion._id !== questionToDelete._id
    );

    setQuestions(updatedQuestions);
  };

  // Hook to display alert after adding/updating questions.
  useEffect(() => {
    // Display a success toast if a question was successfully added.
    if (successAdd) {
      toast.success("Question added successfully", { toastId: "question-add-toast" });
      dispatch(resetSuccessAdd());
    }

    // Display a success toast if a question was successfully updated or added.
    if (successUpdate) {
      toast.success("Question updated/added successfully",
        { toastId: "question-update-toast" });
      dispatch(resetSuccessUpdate());
    }

    // Display an error toast if there was an error during question update or add.
    if (error) {
      toast.error("An error occured on question update or add",
        { toastId: "question-error-toast" });
      dispatch(resetError());
    }
  }, [successAdd, successUpdate, error]);

  const QuestionToAdd = () => {
    switch (selectedQuestionType) {
      case "RADIO":
        return <RadioQuestion question={questionSelectedForEdit} onSave={handleQuestionAdd} />;
      case "SLIDER":
        return <SliderQuestion question={questionSelectedForEdit} onSave={handleQuestionAdd} />;
      case "WRITTEN":
        return <WrittenQuestion question={questionSelectedForEdit} onSave={handleQuestionAdd} />;
      case "LIST":
        return <ListQuestion question={questionSelectedForEdit} onSave={handleQuestionAdd} />;
      default:
        return <></>;
    }
  };

  return (
    <Grid spacing={1} container style={{ marginTop: "2em" }}>
      <Grid item xs={6}>
        <div className={globals.width_100}>
          <h3>Quiz Title:</h3>
          <Input
            fullWidth
            placeholder="Enter Quiz Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={{ marginTop: "1em" }} className={globals.width_100}>
          <FormControl>
            <FormLabel>
              Select a type of question to add:
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="quiz-type-buttons-group"
            >
              {QUIZ_QUESTION_TYPES.map(type => (
                <FormControlLabel
                  key={type}
                  value={type}
                  control={<Radio />}
                  label={type}
                  onClick={() => setSelectedQuestionType(type)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{ marginTop: "1em" }}>
          {QuestionToAdd()}
        </div>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={5} className={`${styles.createdQuestionsCon}`}>
        <AddedQuestions onEdit={handleAddedQuestionEdit} onDelete={handleAddedQuestionDelete} questions={questions} />
      </Grid>
    </Grid>
  );
};

export default QuizTabContent;
